import React, { Component } from "react";
import { Container, Row, Col, Table, Button, InputGroup, FormControl, Modal } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";
import { Line, Bar } from "react-chartjs-2";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL = "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

  

class Events extends Component {
  state = {
    compData: "<< Hello World! >>",
    events: [],
    show: false,
    oneDay: 1000 * 60 * 60 * 24,
    model: [],
    listingData: [],
    unitData:[],
  };

   componentDidMount() {
    let user = actions.isLoggedIn();
    this.setState({ ...user.data });

     axios
      .get(`${baseURL}/api/pulse/last/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ events: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

     axios
      .get(`${baseURL}/api/market/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ listingData: res.data[0] });
      })
      .catch(function (error) {
        console.log(error);
      });

     axios
      .get(`${baseURL}/api/eqrms/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ model: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

      axios
        .get(`${baseURL}/api/unit/${this.props.match.params.id}`)
        .then((res) => {
          this.setState({ unitData: res.data });
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  showAlert = () => {
    this.setState({ show: true });
  };

  hideAlert = (e) => {
    this.setState({ show: false });
    // console.log(e, this.state)
  };

  switchADR = () => {
    let adr;
    if (
      this.state.listingData.price === "" ||
      this.state.events[0].price === null
    ) {
      adr = this.state.events[0].price;
      return adr;
    } else {
      return this.state.listingData.price;
    }
  };

  pulseADR = () => {
    let total = 0;
    let average;
    for (let i = 0; i < this.state.events.length; i++) {
      if (this.state.events[i].price !== null) {
        total += Number(this.state.events[i].price.slice(1).replace(",", ""));
      } else {
        total = null;
      }
    }
    if (total !== null) {
      average = total / this.state.events.length;
    } else {
      average = 0;
    }
    return average;
  };

  renderDay = (date) => {
    let a = new Date(date);
    let days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    let dayOfWeek = days[a.getDay()];
    return dayOfWeek;
  };

  mthdayOcc = (days) => {
    let occ = 0;
    for (let i = 0; i < 1; i++) {
      let counter = 0;
      this.state.events.slice(0, days).forEach((event) => {
        if (event.available !== true) {
          counter++;
        }
      });
      // console.log(counter)
      occ = ((counter / days) * 100).toFixed(0);
    }
    return occ;
  };

  getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  render() {
    let data2 = {
      labels: ["1", "2", "3", "4", "5", "6"],
      datasets: [
        {
          label: "qRM Smart Comp Availability",
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: "rgb(48, 190, 213)",
        },
        {
          label: "Market Supply Nights",
          data: [2, 3, 20, 5, 1, 4],
          backgroundColor: "rgb(243, 192, 82)",
        },
        {
          label: "MyComp Availability",
          data: [3, 10, 13, 15, 22, 30],
          backgroundColor: "rgb(95, 38, 132, 0.8)",
        },
      ],
    };

    let options2 = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    let data = {
      labels: this.state.events.map((date) => {
        return date.calendarDate;
      }),
      datasets: [
        {
          label: "Daily Price ($)",
          backgroundColor: "rgba(48,190,213,0.2)",
          borderColor: "rgba(48,190,213,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(48,190,213,0.4)",
          hoverBorderColor: "rgba(48,190,213,1)",
          yAxisID: "y-axis-1",
          data: this.state.events.map((date) => {
            return Number(date.price.slice(1).replace(",", ""));
          }),
        },
        {
          label: "ADR ($)",
          backgroundColor: "rgba(243,192,82,0.2)",
          borderColor: "rgba(243,192,82,0.8)",
          borderWidth: 1,
          fill: false,
          pointStyle: "line",
          hoverBackgroundColor: "rgba(243,192,82,0.4)",
          hoverBorderColor: "rgba(243,192,82,1)",
          yAxisID: "y-axis-1",
          data: this.state.events.map((date) => {
            let total = 0;
            let average;
            for (let i = 0; i < this.state.events.length; i++) {
              if (this.state.events[i].price !== null) {
                total += Number(
                  this.state.events[i].price.slice(1).replace(",", "")
                );
                // console.log(total);
              } else {
                total = null;
                // console.log(total);
              }
            }
            if (total !== null) {
              average = total / this.state.events.length;
            } else {
              average = 0;
            }
            // console.log(average);
            return average.toFixed(2);
            // return Number(date.price.slice(1));
          }),
        },
        // {
        //   label: "Running ADR ($)",
        //   backgroundColor: "rgba(255, 99, 132,0.2)",
        //   borderColor: "rgba(255, 99, 132,0.8)",
        //   borderWidth: 1,
        //   fill: false,
        //   pointStyle: "line",
        //   hoverBackgroundColor: "rgba(255, 99, 132,0.4)",
        //   hoverBorderColor: "rgba(255, 99, 132,1)",
        //   yAxisID: "y-axis-1",
        //   data: this.state.events.map((date, i) => {
        //     let total = 0;
        //     let average;
        //     for (let i = 0; i < this.state.events.length; i++) {
        //       if (this.state.events[i].price !== null) {
        //         total += Number(
        //           this.state.events[i].price.slice(1).replace(",", "")
        //         );
        //         // console.log(total);
        //       } else {
        //         total = null;
        //         // console.log(total);
        //       }
        //     }
        //     if (total !== null) {
        //       average = total / i;
        //     } else {
        //       average = 0;
        //     }
        //     // console.log(average);
        //     return average.toFixed(2);
        //     // return Number(date.price.slice(1));
        //   }),
        // },
        // {
        //   label: "Booking Curve",
        //   backgroundColor: "rgba(208,63,64,0.2)",
        //   borderColor: "rgba(208,63,64,0.8)",
        //   borderWidth: 1,
        //   fill: false,
        //   pointStyle: "line",
        //   hoverBackgroundColor: "rgba(208,63,64,0.4)",
        //   hoverBorderColor: "rgba(208,63,64,1)",
        //   yAxisID: "y-axis-1",
        //   data: this.state.events.map((date, i) => {
        //     return ((this.pulseADR(i) / this.mthdayOcc(i)) * 25.59);
        //   }),
        // },
        {
          label: "Occupancy Signal",
          backgroundColor: "rgba(0,192,82,0.2)",
          borderColor: "rgba(0,192,82,0.8)",
          borderWidth: 1,
          fill: false,
          pointStyle: "line",
          yAxisID: "y-axis-2",
          hoverBackgroundColor: "rgba(0,192,82,0.4)",
          hoverBorderColor: "rgba(0,192,82,1)",
          data: this.state.events.map((date, i) => {
            return this.mthdayOcc(i);
          }),
        },
      ],
    };
    let options = {
      title: {
        display: true,
        text: "Pricing Breakdown",
      },
      elements: {
        point: {
          radius: 1,
        },
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            scaleLabel: {
              display: true,
              labelString: "($) Daily Price - USD",
            },
            // ticks: {
            //   max:1000,
            //   min:0,
            // }
          },
          {
            type: "linear",
            display: true,
            position: "right",
            id: "y-axis-2",
            gridLines: {
              drawOnArea: false,
            },
            scaleLabel: {
              display: true,
              labelString: "Running Occupancy (%)",
            },
            // ticks: {
            //   max:0.2,
            //   min:0,
            // }
          },
        ],
      },
    };

    // console.log(this.state.events);
    const authToken = localStorage.getItem(AUTH_TOKEN);
    if (authToken) {
      return (
        <React.Fragment>
          <Modal
            size="md"
            show={this.state.show}
            onHide={() => this.hideAlert()}
            aria-labelledby="modalError"
          >
            <Modal.Header closeButton>
              <Modal.Title id="modalError">
                <strong>Insights for Date</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Bar data={data2} width={50} height={50} options={options2} />
            </Modal.Body>
          </Modal>
          <Container style={{ marginTop: "2em", marginBottom: "2em" }}>
            <Row style={{ marginTop: "2rem" }}>
              <Col xs={12} style={{ marginBottom: "0.5em" }}>
                <h1>
                  Daily Event Pricing{" "}
                  <Button
                    as={Link}
                    to={"/calendar/" + this.props.match.params.id}
                    variant="ml-4"
                    className="bg-custom-1"
                    style={{color:"white"}}
                  >
                    Calendar View
                  </Button>
                  <Button
                    variant="outline-warning ml-2"
                    as={Link}
                    to={"/listing/" + this.props.match.params.id}
                  >
                    Listing Overview
                  </Button>
                </h1>
              </Col>
            </Row>
            <Row>
            <Col>
            <hr/>
              <Button variant="outline-dark">7d Occ:{" "}<span className={"badge badge-"+(this.mthdayOcc(7) >= 80 ? "success":(this.mthdayOcc(7) >= 60 ? "warning":"danger"))+" badge-pill"}>{this.mthdayOcc(7)}%</span> </Button>
              <Button variant="outline-dark ml-2">15d Occ:{" "}<span className={"badge badge-"+(this.mthdayOcc(15) >= 75 ? "success":(this.mthdayOcc(15) >= 60 ? "warning":"danger"))+" badge-pill"}>{this.mthdayOcc(15)}%</span> </Button>
              <Button variant="outline-dark ml-2">30d Occ:{" "}<span className={"badge badge-"+(this.mthdayOcc(30) >= 75 ? "success":(this.mthdayOcc(30) >= 60 ? "primary":"warning"))+" badge-pill"}>{this.mthdayOcc(30)}%</span> </Button>
              <Button variant="outline-dark ml-2">60d Occ:{" "}<span className={"badge badge-"+(this.mthdayOcc(60) >= 70 ? "success":(this.mthdayOcc(60) >= 50 ? "secondary":"warning"))+" badge-pill"}>{this.mthdayOcc(60)}%</span></Button>
            <hr/>
            </Col>
            </Row>
            <Line data={data} width={100} height={150} options={options} />

            {this.state.events.length > 0 ? (
              <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                <thead>
                  <tr>
                    <th>Occ</th>
                    <th>Date</th>
                    <th>Day</th>
                    <th>Lead Time</th>
                    <th>MinNight</th>
                    <th>Occ Signal</th>
                    <th>EMRRq $</th>
                    <th>Current Price</th>
                   {this.state.unitData.length > 0 ? <th>Override Price</th> :<></>}
                    <th>View {this.state.unitData.length > 0 ? "/ Actions": ""}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.events.map((event, i) => {
                    return (
                      <tr key={i}>
                        <td>{event.available ? " 🟢 " : " 🔴  "}</td>
                        <td>{event.calendarDate}</td>
                        <td>{this.renderDay(event.calendarDate)}</td>
                        <td>
                          {(
                            (new Date(event.calendarDate) - new Date()) /
                              this.state.oneDay +
                            1
                          ).toFixed(0)}
                        </td>
                        <td>{event.minNights}</td>
                        <td>
                          {this.mthdayOcc(i) === "NaN"
                            ? "-"
                            : this.mthdayOcc(i) + "%"}
                        </td>
                        <td className={event.available ? "" : "blurry-text"}>
                          $
                          {event.price !== null && event.available
                            ? this.getRandomInt(
                                Number(event.price.replace(/[,|$]/g, "")) *
                                  0.92,
                                Number(event.price.replace(/[,|$]/g, "")) *
                                  1.123
                              )
                            : "**.**"}
                        </td>
                        <td className={event.available ? "" : "blurry-text"}>
                          {event.price !== null && event.available
                            ? event.price
                            : "$**.**"}
                        </td>
                        {this.state.unitData.length > 0 ? (
                          <td>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder={
                                  event.available ? event.price : "-"
                                }
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                disabled={!event.available}
                              />
                            </InputGroup>
                          </td>
                        ) : (
                          <></>
                        )}
                        <td>
                          <Button
                            // as={Link}
                            // to={`/event/${event._id}`}
                            onClick={(e) => this.showAlert(e)}
                            variant="outline-dark"
                            size="sm"
                            // disabled={
                            //   event.calendarDate >=
                            //   String(new Date().toISOString()).slice(0, 10)
                            //     ? !event.available
                            //     : true
                            // }
                          >
                            Insights 💡
                          </Button>
                          {this.state.unitData.length > 0 ? (
                            <Button
                              // as={Link}
                              // to={`/event/${event._id}`}
                              variant="outline-success"
                              size="sm ml-2"
                              disabled={
                                event.calendarDate >=
                                String(new Date().toISOString()).slice(0, 10)
                                  ? !event.available
                                  : true
                              }
                            >
                              Sync 🔄
                            </Button>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <h4 className="loading">Loading Events for Listing...</h4>
            )}
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Events;