import React, { Component } from 'react';
import {Row, Col, Form, Container, Button, InputGroup} from 'react-bootstrap'
import actions from '../services/index'
import { AUTH_TOKEN } from "../constants.js";
import { Redirect } from 'react-router-dom';
import qrm from "../img/quibbleRMlogo2.png";


 

class Login extends Component {
  componentDidMount() {}

  state = {
    
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = (e) => {
    e.preventDefault();
    actions
    .logIn(this.state)
    .then((user) => {
        localStorage.setItem(AUTH_TOKEN, user.data)
        this.props.setUser({ ...user.data });
        this.props.history.push("/hub");
      })
      .catch((error) => {
        console.error(error);
      });
  };


  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN);
    if (!authToken) {
                      return (
                        <>
                          <Container
                            style={{ marginBottom: "10rem", height:"100vh" }}
                            >
                            <Row
                              className="topRowHome"
                              style={{ marginBottom: "1em" }}
                            >
                              <Col
                                xs={{ span: 10, offset: 1 }}
                                lg={{ span: 4, offset: 4 }}
                              >
                                {/* <h4
                                  className="featurette-heading text-center"
                                  style={{ fontSize: "4rem" }}
                                >
                                  qRM-DEV
                                </h4> */}
                                <center>
                                  <img
                                    height="100rem"
                                    src={qrm}
                                    alt="QuibbleRM"
                                    style={{ marginBottom: "2rem" }}
                                  />
                                </center>
                              </Col>
                            </Row>

                            <Form>
                              <Row style={{ marginBottom: "2em" }}>
                                <Col
                                  xs={{ span: 10, offset: 1 }}
                                  lg={{ span: 4, offset: 4 }}
                                >
                                  <Form.Group>
                                    <InputGroup className="mb-3">
                                      <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1">
                                          <span role="img" aria-label="user">
                                            {" "}
                                            👤
                                          </span>
                                        </InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <Form.Control
                                        placeholder="Username..."
                                        name="email"
                                        type="email"
                                        aria-describedby="basic-addon1"
                                        onChange={this.handleChange}
                                      />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                      <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon2">
                                          {" "}
                                          <span role="img" aria-label="lock">
                                            {" "}
                                            🔒
                                          </span>
                                        </InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <Form.Control
                                        placeholder="Password..."
                                        name="password"
                                        type="password"
                                        aria-describedby="basic-addon2"
                                        onChange={this.handleChange}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <br />
                                  <Button
                                    block
                                    variant="primary"
                                    type="submit"
                                    value="Log In"
                                    onClick={(e) => this.handleSubmit(e)}
                                  >
                                    Log In
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </Container>
                        </>
                      );
                    }
                    else {
                      return <Redirect to="/units"/>
                    }
  }
}


export default Login;