import React, { Component } from "react";
import { Button, Table, Form, FormControl, Container, Row, Col, FormGroup} from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL =
      "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

class ClientListings extends Component {
  state = {
    listings: [],
    options: [],
    selectedOptionlisting: null,
    filteredItems: [],
    search: "",
    loading: false,
    units: [],
    pulses: [],
    client: [],
  };

  componentDidMount() {
    axios
      .get(`${baseURL}/api/clients/${this.props.match.params.id}`)
      .then((res) => {
        // console.log(res.data);
        this.setState({ client: res.data,
                        clientName : res.data[0].name });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/unit/client/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ units: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/pulse/units`)
      .then((res) => {
        // console.log(res.data)
        this.setState({ pulses: res.data, loading: true });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .get(`${baseURL}/api/pulse/get/${this.state.listingId}`)
      // .get(`http://localhost:4000/api/pulse/get/${this.state.listingId}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    alert(
      "Data has been capture successfully! Click ok to proceed to Listing Event Pricing Details."
    );
    await this.props.history.push(`/events/${this.state.listingId}`);
  };

  filterOptions = (e) => {
    // eslint-disable-next-line
    let filteredItems = this.state.units.filter((listing) => {
      if (listing.listing_nickname) {
        return (
          listing.listing_nickname
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          listing.id.includes(String(e.target.value)) ||
          listing.smart_location
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          listing.owner_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        );
      }
    });
    this.setState({
      filteredItems,
      search: e.target.value,
    });
    console.log(this.state.filteredItems);
  };

  untanglePulse = () => {
    console.log(this.state.pulses);
  };

  mthdayOcc = (days, id) => {
    let occ = 0;
    let list = this.state.pulses;
    let array = list.filter((pulse) => pulse.listingId === id);
    for (let i = 0; i < 1; i++) {
      let counter = 0;
      array.slice(0, days).forEach((event) => {
        if (event.available !== true) {
          counter++;
        }
      });
      occ = ((counter / days) * 100).toFixed(0);
    }
    return occ;
  };

  showOptions = (e) => {
    return this.state.filteredItems.map((unit, i) => {
      return (
        <tr key={i}>
          <td>{unit.listing_nickname}</td>
          <td>{unit.listing_state}</td>
          <td>{unit.smart_location}</td>
          <td>{unit.id}</td>
          <td>
            <Button as={Link} to={"/events/" + unit.id} variant="info">
              Pricing List
            </Button>
            <Button
              as={Link}
              to={"/reservations/" + unit.id}
              variant="primary ml-2"
            >
              Reservations
            </Button>
            <Button
              as={Link}
              to={"/calendar/" + unit.id}
              variant="success ml-2"
            >
              🗓
            </Button>
            <Button as={Link} to={"/unit/" + unit.id} variant="warning ml-2">
              {" "}
              View{" "}
            </Button>
          </td>
        </tr>
      );
    });
  };

  clientName = () => {
    return String(this.state.clientName)
  }

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN);
    if (authToken) {
      return (
        <React.Fragment>
          <Container style={{ marginTop: "2em", minHeight: "75vh" }}>
            <Row>
              <Col xs={12}>
                <h1>{this.clientName()} Property Units</h1>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1em", marginTop: "0.5em" }}>
              <Col lg={8} md={6} xs={12} style={{ marginBottom: "0.5em" }}>
                {/* <Button variant="success" onClick={this.showModalNewUnit}>
                  + Add New Unit
                </Button> */}
                <Form inline>
                  <FormGroup onChange={this.filterOptions}>
                    <FormControl
                      type="text"
                      name="search"
                      placeholder="Type your search..."
                      className="mr-sm-2"
                      onChange={this.handleChange}
                    />
                    <Button
                      variant="info"
                      className="bg-custom-1"
                      style={{ color: "white" }}
                    >
                      Search
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
              <Col lg={4} md={6} xs={12}>
                {/* <Form inline>
                  <FormControl
                    type="text"
                    name="search"
                    placeholder="Type your search..."
                    className="mr-sm-2"
                    onChange={this.handleChange}
                  />
                  <Button variant="info">Search</Button>
                </Form> */}
              </Col>
            </Row>
            {this.state.filteredItems.length === 0 ? (
              <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                style={{ textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th>-qRM-</th>
                    <th>AirBnB ID</th>
                    <th>Name</th>
                    <th className={this.state.loading ? "" : "loading"}>
                      7d-Occ
                    </th>
                    <th className={this.state.loading ? "" : "loading"}>
                      15d-Occ
                    </th>
                    <th className={this.state.loading ? "" : "loading"}>
                      30d-Occ
                    </th>
                    <th className={this.state.loading ? "" : "loading"}>
                      60d-Occ
                    </th>
                    <th>Location</th>
                    <th>Actions</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.units.map((unit, i) => {
                    return (
                      <tr key={i}>
                        <td>{unit.isActive ? " 🟢 " : " 🔴  "}</td>
                        <td>{unit.id}</td>
                        <td>{unit.listing_nickname}</td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(7, unit.id) >= 80
                                ? "success"
                                : this.mthdayOcc(7, unit.id) >= 50
                                ? "warning"
                                : "danger") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(7, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(15, unit.id) >= 75
                                ? "success"
                                : this.mthdayOcc(15, unit.id) >= 60
                                ? "warning"
                                : "danger") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(15, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(30, unit.id) >= 75
                                ? "success"
                                : this.mthdayOcc(30, unit.id) >= 60
                                ? "primary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(30, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(60, unit.id) >= 70
                                ? "success"
                                : this.mthdayOcc(60, unit.id) >= 50
                                ? "secondary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(60, unit.id)}%
                          </span>
                        </td>
                        <td>{unit.smart_location}</td>
                        <td>
                          {unit.isActive ? (
                            <>
                              <Button
                                as={Link}
                                to={"/events/" + unit.id}
                                variant="outline-info"
                                className="bg-custom-1"
                                style={{ color: "white" }}
                              >
                                Pricing List
                              </Button>

                              <Button
                                as={Link}
                                to={"/calendar/" + unit.id}
                                variant="outline-info ml-2"
                                className="bg-custom-3"
                                style={{ color: "white" }}
                              >
                                🗓
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}

                          <Button
                            as={Link}
                            to={"/listing/" + unit.id}
                            variant="warning ml-2"
                            className="bg-custom-7"
                            style={{ color: "white" }}
                          >
                            {" "}
                            View{" "}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : this.state.filteredItems.length !== 0 ? (
              <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                style={{ textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th>-qRM-</th>
                    <th>AirBnB ID</th>
                    <th>Name</th>
                    <th>7d-Occ</th>
                    <th>15d-Occ</th>
                    <th>30d-Occ</th>
                    <th>60d-Occ</th>
                    <th>Location</th>
                    <th>Actions</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.filteredItems.map((unit, i) => {
                    return (
                      <tr key={i}>
                        <td>{unit.isActive ? " 🟢 " : " 🔴  "}</td>
                        <td>{unit.id}</td>
                        <td>{unit.listing_nickname}</td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(7, unit.id) >= 80
                                ? "success"
                                : this.mthdayOcc(7, unit.id) >= 60
                                ? "warning"
                                : "danger") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(7, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(15, unit.id) >= 75
                                ? "success"
                                : this.mthdayOcc(15, unit.id) >= 60
                                ? "warning"
                                : "danger") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(15, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(30, unit.id) >= 75
                                ? "success"
                                : this.mthdayOcc(30, unit.id) >= 60
                                ? "primary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(30, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(60, unit.id) >= 70
                                ? "success"
                                : this.mthdayOcc(60, unit.id) >= 50
                                ? "secondary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(60, unit.id)}%
                          </span>
                        </td>
                        <td>{unit.smart_location}</td>
                        <td>
                          {unit.isActive ? (
                            <>
                              <Button
                                as={Link}
                                to={"/events/" + unit.id}
                                variant="outline-info"
                                className="bg-custom-1"
                                style={{ color: "white" }}
                              >
                                Pricing List
                              </Button>

                              <Button
                                as={Link}
                                to={"/calendar/" + unit.id}
                                variant="outline-info ml-2"
                                className="bg-custom-3"
                                style={{ color: "white" }}
                              >
                                🗓
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}

                          <Button
                            as={Link}
                            to={"/listing/" + unit.id}
                            variant="warning ml-2"
                            className="bg-custom-7"
                            style={{ color: "white" }}
                          >
                            {" "}
                            View{" "}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <h4 className="loading">
                Please wait while we load the properties...
              </h4>
            )}
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default ClientListings;
