import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Container, Col, Row, Button } from "react-bootstrap";
import qrm from "../img/quibbleRM_bg.png"
// import axios from "axios"



class Home extends Component {

  

  render() {
    return (
      <React.Fragment>
        <Container style={{ height: "100vh", marginTop: "6rem" }}>
          <Row>
            <Col>
              <center>
                <h1>
                  <b>QuibbleRM - Currenlty Under Construction</b>
                </h1>
              </center>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 10, offset: 1 }}
              lg={{ span: 4, offset: 4 }}
              className="center2"
            >
              <img src={qrm} alt="QuibbleRM" width="300px" />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 12 }} lg={{ span: 10, offset: 1 }}>
              <center>
                <h2>
                  Developing Next-Generation Revenue Management Software for
                  Vacation Rentals
                </h2>
              </center>
            </Col>
          </Row>
          <Row style={{ marginTop: "3rem" }}>
            <Col xs={{ span: 10, offset: 1 }} lg={{ span: 4, offset: 4 }}>
              <center>
                <Button href="https://quibblerm.com" block size="lg" variant="info">
                  Learn More
                </Button>
              </center>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Home;