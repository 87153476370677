import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Col, Row, Card, CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import unit from "../img/unit.png";
import tag from "../img/tag.png";
import portfolio from "../img/portfolio.png";
import location from "../img/location.png";


class Rules extends Component {
  render() {
    return (
      <React.Fragment>
        <Container
          style={{ height: "100%", marginTop: "6rem", marginBottom: "4rem" }}
        >
          <Row>
            <Col>
              <center>
                <h1>
                  <b>Rules Manager</b>
                </h1>
              </center>
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <CardGroup>
              <Card
                as={Link}
                to="/Entries"
                style={{ textDecoration: "none", width: "18rem" }}
              >
                <Card.Img variant="top" src={unit} width="500em" />
                <Card.Body style={{ color: "black" }}>
                  <Card.Title>Individual Customizations</Card.Title>
                  <Card.Text>
                    Create, View and Edit individual listing pricing model
                    customizations.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                as={Link}
                to="/"
                style={{
                  textDecoration: "none",
                  marginLeft: "2rem",
                  width: "18rem",
                }}
              >
                <Card.Img variant="top" src={location} />
                <Card.Body style={{ color: "black" }}>
                  <Card.Title>Location Customizations</Card.Title>
                  <Card.Text>
                    Create, View and Edit customizations based on geographic
                    locale.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                as={Link}
                to="/"
                style={{
                  textDecoration: "none",
                  marginLeft: "2rem",
                  width: "18rem",
                }}
              >
                <Card.Img variant="top" src={tag} />
                <Card.Body style={{ color: "black" }}>
                  <Card.Title>Group + Tag Customizations</Card.Title>
                  <Card.Text>
                    Create, View and Edit customizations to demand models on a
                    tag basis.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                as={Link}
                to="/"
                style={{
                  textDecoration: "none",
                  marginLeft: "2rem",
                  width: "18rem",
                }}
              >
                <Card.Img variant="top" src={portfolio} width="50%" />
                <Card.Body style={{ color: "black" }}>
                  <Card.Title>Portfolio Customizations</Card.Title>
                  <Card.Text>
                    Create, View and Edit customizations and rules set to your
                    entire porfolio.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardGroup>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Rules;
