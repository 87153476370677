import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  FormControl,
  Container,
  Row,
  Col,
  FormGroup,
  ToggleButton,
  ButtonGroup
} from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
// import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL = "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

const countFormat = [
  {
    // 0 - 999
    letter: "",
    limit: 1e3,
  },
  {
    // 1,000 - 999,999
    letter: "K",
    limit: 1e6,
  },
  {
    // 1,000,000 - 999,999,999
    letter: "M",
    limit: 1e9,
  },
  {
    // 1,000,000,000 - 999,999,999,999
    letter: "B",
    limit: 1e12,
  },
  {
    // 1,000,000,000,000 - 999,999,999,999,999
    letter: "T",
    limit: 1e15,
  },
];

class Units extends Component {
  state = {
    listings: [],
    options: [],
    selectedOptionlisting: null,
    filteredItems: [],
    search: "",
    loading: false,
    units: [],
    pulses: [],
    toggleEnabled:true,
  };

  componentDidMount() {
    // let user =  actions.isLoggedIn();
    // this.setState({ ...user.data });

    axios
      .get(`${baseURL}/api/unit`)
      // .get(`http://localhost:4000/api/unit`)
      .then((res) => {
        this.setState({ units: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/pulse/units`)
      // .get(`http://localhost:4000/api/unit`)
      .then((res) => {
        // console.log(res.data)
        this.setState({ pulses: res.data, loading: true });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .get(`${baseURL}/api/pulse/get/${this.state.listingId}`)
      // .get(`http://localhost:4000/api/pulse/get/${this.state.listingId}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    alert(
      "Data has been capture successfully! Click ok to proceed to Listing Event Pricing Details."
    );
    await this.props.history.push(`/events/${this.state.listingId}`);
  };

  filterOptions = (e) => {
    // eslint-disable-next-line
    let filteredItems = this.state.units.filter((listing) => {
      if (listing.listing_nickname) {
        return (
          listing.listing_nickname
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          // listing.id.includes(String(e.target.value)) ||
          listing.smart_location
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          listing.owner_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        );
      }
    });
    this.setState({
      filteredItems,
      search: e.target.value,
    });
    console.log(this.state.filteredItems);
  };

  toggleEnabled = () => {
    this.setState({
      toggleEnabled: false
    })
  }

  untanglePulse = () => {
    console.log(this.state.pulses);
  };

  mthdayOcc = (days, id) => {
    let occ = 0;
    let list = this.state.pulses;
    let array = list.filter((pulse) => pulse.listingId === id);
    for (let i = 0; i < 1; i++) {
      let counter = 0;
      array.slice(0, days).forEach((event) => {
        if (event.available !== true) {
          counter++;
        }
      });
      occ = ((counter / days) * 100).toFixed(0);
    }
    return occ;
  };

  showOptions = (e) => {
    return this.state.filteredItems.map((unit, i) => {
      return (
        <tr key={i}>
          <td>{unit.listing_nickname}</td>
          <td>{unit.listing_state}</td>
          <td>{unit.smart_location}</td>
          <td>{unit.id}</td>
          <td>
            <Button as={Link} to={"/events/" + unit.id} variant="info">
              Pricing List
            </Button>
            <Button
              as={Link}
              to={"/reservations/" + unit.id}
              variant="primary ml-2"
            >
              Reservations
            </Button>
            <Button
              as={Link}
              to={"/calendar/" + unit.id}
              variant="success ml-2"
            >
              🗓
            </Button>
            <Button as={Link} to={"/unit/" + unit.id} variant="warning ml-2">
              {" "}
              View{" "}
            </Button>
          </td>
        </tr>
      );
    });
  };

  // selectItem = (item) => {
  //   // console.log(item);
  //   this.setState({
  //     searchlisting: item.id,
  //   });
  //   this.filterOptions({ target: { value: item.id } });
  //   this.setState({
  //     filteredItems: [],
  //   });
  // };

  // showDetails = (e, i) => {
  //   if (this.state.searchlisting) {
  //     this.setState({ selectedOptionlisting: this.state.search.id }, () => {
  //       console.log("hey there!!");
  //     });
  //   } else {
  //     e.preventDefault();
  //   }
  // };

  // searchAction = () => {
  //   this.setState({
  //     loading: true,
  //   });
  // };

  // Format Method:
  formatCount = (value) => {
    let format = countFormat.find((format) => value < format.limit);

    value = (1000 * value) / format.limit;
    value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

    return value + format.letter;
  };

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN);

    const radios = [
      { name: "Occupancy (%)", value: "1" },
      { name: "ADR ($)", value: "2" },
      { name: "RevPAR ($)", value: "3" },
    ];
    // console.log(this.state.pulses);
    if (authToken) {
      return (
        <React.Fragment>
          <Container style={{ marginTop: "2em", height: "100%" }}>
            <Row>
              <Col lg={10}>
                <h1>Property Units</h1>
              </Col>
              <Col lg={2}>
                <Button
                  variant="outline-primary mt-2"
                  as={Link}
                  to="/mymarkets/"                
                >
                  Markets View
                </Button>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1em", marginTop: "0.5em" }}>
              <Col lg={6} md={6} xs={12} style={{ marginBottom: "0.5em" }}>
                <Form inline>
                  <FormGroup onChange={this.filterOptions}>
                    <FormControl
                      type="text"
                      name="search"
                      placeholder="Type your search..."
                      className="mr-sm-2"
                      onChange={this.handleChange}
                    />
                    <Button
                      variant="info"
                      className="bg-custom-1"
                      style={{ color: "white" }}
                    >
                      Search
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
              <Col lg={6} md={6} xs={12} style={{ marginBottom: "0.5em" }}>
                <Form inline>
                  <ButtonGroup className="pull-right" toggle>
                    {radios.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value={radio.value}
                        checked={radio.name === "Occupancy (%)" ? true : false}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                  <Form.Group
                    style={{ marginLeft: "2rem" }}
                    controlId="exampleForm.ControlSelect1"
                  >
                    <Form.Label>View Count</Form.Label>
                    <Form.Control style={{ marginLeft: "1rem" }} as="select">
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>100</option>
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col lg={4} md={6} xs={12}>
                {/* <Form inline>
                  <FormControl
                    type="text"
                    name="search"
                    placeholder="Type your search..."
                    className="mr-sm-2"
                    onChange={this.handleChange}
                  />
                  <Button variant="info">Search</Button>
                </Form> */}
              </Col>
            </Row>
            {this.state.filteredItems.length === 0 ? (
              <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                style={{ textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th>- qRM Sync -</th>
                    {/* <th>AirBnB ID</th> */}
                    <th>Listing Name</th>
                    {/* <th>Location</th> */}
                    <th className={this.state.loading ? "" : "loading"}>Jan</th>
                    <th className={this.state.loading ? "" : "loading"}>Feb</th>
                    <th className={this.state.loading ? "" : "loading"}>Mar</th>
                    <th className={this.state.loading ? "" : "loading"}>Apr</th>
                    <th className={this.state.loading ? "" : "loading"}>May</th>
                    <th className={this.state.loading ? "" : "loading"}>Jun</th>
                    <th>Actions / Manage</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.units.map((unit, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Form.Check
                            type="switch"
                            id={"property" + i}
                            name={"unit_" + i}
                            label={
                              unit.isActive && this.state.toggleEnabled
                                ? "Active"
                                : "Inactive"
                            }
                            checked={
                              unit.isActive && this.state.toggleEnabled
                                ? true
                                : false
                            }
                            onClick={(e) => this.toggleEnabled(e)}
                          />
                          {/* {unit.isActive ? " 🟢 " : " 🔴  "} */}
                        </td>
                        {/* <td>{unit.id}</td> */}
                        <td>{unit.listing_nickname}</td>
                        {/* <td>{unit.smart_location}</td> */}
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(7, unit.id) >= 80
                                ? "success"
                                : this.mthdayOcc(7, unit.id) >= 50
                                ? "warning"
                                : "danger") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(7, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(15, unit.id) >= 75
                                ? "success"
                                : this.mthdayOcc(15, unit.id) >= 60
                                ? "warning"
                                : "danger") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(15, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(30, unit.id) >= 75
                                ? "success"
                                : this.mthdayOcc(30, unit.id) >= 60
                                ? "primary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(30, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(60, unit.id) >= 70
                                ? "success"
                                : this.mthdayOcc(60, unit.id) >= 50
                                ? "secondary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(60, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(60, unit.id) >= 70
                                ? "success"
                                : this.mthdayOcc(60, unit.id) >= 50
                                ? "secondary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(60, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(60, unit.id) >= 70
                                ? "success"
                                : this.mthdayOcc(60, unit.id) >= 50
                                ? "secondary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(60, unit.id)}%
                          </span>
                        </td>
                        <td>
                          {/* {unit.isActive ? (
                            <>
                              <Button
                                as={Link}
                                to={"/events/" + unit.id}
                                variant="outline-info"
                                className="bg-custom-1"
                                style={{ color: "white" }}
                              >
                                Pricing List
                              </Button>

                              <Button
                                as={Link}
                                to={"/calendar/" + unit.id}
                                variant="outline-info ml-2"
                                className="bg-custom-3"
                                style={{ color: "white" }}
                              >
                                🗓
                              </Button>
                            </>
                          ) : (
                            <></>
                          )} */}

                          <Button
                            as={Link}
                            to={"/listing/" + unit.id}
                            variant="success"
                            // className="bg-custom-7"
                            style={{ color: "white" }}
                          >
                            {" "}
                            View{" "}
                          </Button>
                          <Button
                            as={Link}
                            to={"/demand/" + unit.id}
                            variant="primary ml-2"
                            // className="bg-custom-7"
                            style={{ color: "white" }}
                          >
                            {" "}
                            Demand{" "}
                          </Button>
                          <Button
                            as={Link}
                            to={"/forecast/" + unit.id}
                            variant="secondary ml-2"
                            // className="bg-custom-7"
                            style={{ color: "white" }}
                          >
                            {" "}
                            Forecast{" "}
                          </Button>
                          <Button
                            as={Link}
                            to={"/rules/" + unit.id}
                            variant="info ml-2"
                            // className="bg-custom-7"
                            style={{ color: "white" }}
                          >
                            {" "}
                            Rules{" "}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : this.state.filteredItems.length !== 0 ? (
              <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                style={{ textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th>- qRM Sync -</th>
                    {/* <th>AirBnB ID</th> */}
                    <th>Listing Name</th>
                    {/* <th>Location</th> */}
                    <th className={this.state.loading ? "" : "loading"}>Jan</th>
                    <th className={this.state.loading ? "" : "loading"}>Feb</th>
                    <th className={this.state.loading ? "" : "loading"}>Mar</th>
                    <th className={this.state.loading ? "" : "loading"}>Apr</th>
                    <th className={this.state.loading ? "" : "loading"}>May</th>
                    <th className={this.state.loading ? "" : "loading"}>Jun</th>
                    <th>Actions / Manage</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.filteredItems.map((unit, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Form.Check
                            type="switch"
                            id={"property" + i}
                            name={"unit_" + i}
                            label={
                              unit.isActive && this.state.toggleEnabled
                                ? "Active"
                                : "Inactive"
                            }
                            checked={
                              unit.isActive && this.state.toggleEnabled
                                ? true
                                : false
                            }
                            onClick={(e) => this.toggleEnabled(e)}
                          />
                          {/* {unit.isActive ? " 🟢 " : " 🔴  "} */}
                        </td>
                        {/* <td>{unit.id}</td> */}
                        <td>{unit.listing_nickname}</td>
                        <td>{unit.smart_location}</td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(7, unit.id) >= 80
                                ? "success"
                                : this.mthdayOcc(7, unit.id) >= 50
                                ? "warning"
                                : "danger") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(7, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(15, unit.id) >= 75
                                ? "success"
                                : this.mthdayOcc(15, unit.id) >= 60
                                ? "warning"
                                : "danger") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(15, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(30, unit.id) >= 75
                                ? "success"
                                : this.mthdayOcc(30, unit.id) >= 60
                                ? "primary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(30, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(60, unit.id) >= 70
                                ? "success"
                                : this.mthdayOcc(60, unit.id) >= 50
                                ? "secondary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(60, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(60, unit.id) >= 70
                                ? "success"
                                : this.mthdayOcc(60, unit.id) >= 50
                                ? "secondary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(60, unit.id)}%
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              "badge badge-" +
                              (this.mthdayOcc(60, unit.id) >= 70
                                ? "success"
                                : this.mthdayOcc(60, unit.id) >= 50
                                ? "secondary"
                                : "warning") +
                              " badge-pill"
                            }
                          >
                            {this.mthdayOcc(60, unit.id)}%
                          </span>
                        </td>
                        <td>
                          {/* {unit.isActive ? (
                            <>
                              <Button
                                as={Link}
                                to={"/events/" + unit.id}
                                variant="outline-info"
                                className="bg-custom-1"
                                style={{ color: "white" }}
                              >
                                Pricing List
                              </Button>

                              <Button
                                as={Link}
                                to={"/calendar/" + unit.id}
                                variant="outline-info ml-2"
                                className="bg-custom-3"
                                style={{ color: "white" }}
                              >
                                🗓
                              </Button>
                            </>
                          ) : (
                            <></>
                          )} */}

                          <Button
                            as={Link}
                            to={"/listing/" + unit.id}
                            variant="success"
                            // className="bg-custom-7"
                            style={{ color: "white" }}
                          >
                            {" "}
                            View{" "}
                          </Button>

                          <Button
                            as={Link}
                            to={"/demand/" + unit.id}
                            variant="primary ml-2"
                            // className="bg-custom-7"
                            style={{ color: "white" }}
                          >
                            {" "}
                            Demand{" "}
                          </Button>
                          <Button
                            as={Link}
                            to={"/forecast/" + unit.id}
                            variant="secondary ml-2"
                            // className="bg-custom-7"
                            style={{ color: "white" }}
                          >
                            {" "}
                            Forecast{" "}
                          </Button>
                          <Button
                            as={Link}
                            to={"/rules/" + unit.id}
                            variant="info ml-2"
                            // className="bg-custom-7"
                            style={{ color: "white" }}
                          >
                            {" "}
                            Rules{" "}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <h4 className="loading">
                Please wait while we load the properties...
              </h4>
            )}
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Units;
