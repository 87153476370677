/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Container, Button, Row, Col, Card, Modal, Form, FormGroup} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
// import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL = "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

class ListingOverview extends Component {
  state = {
    events: [],
    showModalEvent: false,
    oneDay: 1000 * 60 * 60 * 24,
    model: [],
    listingData: [],
    reviews: [],
    unitData: [],
    settingsModalShow: false,
    customizationsModalShow: false,
    monthlyBasePrice: false,
    lmp: false,
    odp: false,
    msu: false,
    dowa: false,
    mfop: false,
    mwp: false,
    tcl: false,
  };

  componentDidMount() {
    // let user = actions.isLoggedIn();
    // this.setState({ ...user.data });

    axios
      .get(`${baseURL}/api/pulse/last/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ events: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/market/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ listingData: res.data[0] });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/reviews/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ reviews: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/eqrms/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ model: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/unit/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ unitData: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onImgErrorSmall = (e) => {
    console.log(e.target);
    e.target.src =
      "https://www.wolflair.com/wp-content/uploads/2017/01/placeholder.jpg";
    // disable onerror to prevent endless loop
    e.target.onError = "";
    return true;
  };

  switchADR = () => {
    let adr;
    if (
      this.state.listingData.price === "" ||
      this.state.events[0].price === null
    ) {
      adr = this.state.events[0].price;
      return adr;
    } else {
      return this.state.listingData.price;
    }
  };

  pulseADR = () => {
    let total = 0;
    let average;
    for (let i = 0; i < this.state.events.length; i++) {
      if (this.state.events[i].price !== null) {
        total += Number(this.state.events[i].price.slice(1));
        // console.log(total);
      } else {
        total = null;
        // console.log(total);
      }
    }
    if (total !== null) {
      average = total / this.state.events.length;
    } else {
      average = 0;
    }
    // console.log(average);
    return average;
  };

  renderDay = (date) => {
    let a = new Date(date);
    let days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    let dayOfWeek = days[a.getDay()];
    return dayOfWeek;
  };

  mthdayOcc = (days) => {
    let occ = 0;
    for (let i = 0; i < 1; i++) {
      let counter = 0;
      this.state.events.slice(0, days).forEach((event) => {
        if (event.available !== true) {
          counter++;
        }
      });
      // console.log(counter)
      occ = ((counter / days) * 100).toFixed(0);
    }
    return occ;
  };

  getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  reviewList() {
    return this.state.reviews.map((reviews, i) => {
      return (
        <Card
          key={i}
          style={{ marginTop: "1rem", marginRight: "1rem", width: "100%" }}
        >
          <Card.Header>
            <b>{reviews.reviewerName}</b>
          </Card.Header>

          <Card.Body>
            <img
              variant="top"
              src={reviews.reviewerPhoto}
              style={{ width: "5vw", marginBottom: "1rem" }}
            />

            <Card.Title>{reviews.rating}</Card.Title>
            <Card.Text>
              <b>Guest Review:</b> <i>"{reviews.comments}"</i>
              {reviews.response !== null ? (
                <>
                  <br />
                  <br /> <b>{reviews.revieweeName}'s Response:</b>{" "}
                  <i>"{reviews.response}"</i>
                </>
              ) : (
                ""
              )}
            </Card.Text>
            {/* <Button variant="primary">Go somewhere</Button> */}
          </Card.Body>
          <Card.Footer className="text-muted">
            {reviews.localizedDate}
          </Card.Footer>
        </Card>
      );
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  showAlert = (e) => {
    this.setState({ [e.target.name]: true });
  };

  toggleSwitch = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  hideAlert = (e) => {
    this.setState({ settingsModalShow: false, customizationsModalShow: false });
    // console.log(e, this.state)
  };

  refreshPage = () => {
    window.location.reload(false);
  }

  handleSubmitSettings = async (e) => {
    e.preventDefault();
    let payload = {
                    "basePrice": this.state.BasePrice,
                    "minPrice": this.state.MinPrice,
                    "maxPrice": this.state.MaxPrice,
                  }
    await axios
      .post(`${baseURL}/api/unit/${this.props.match.params.id}`, payload)
      //   .get(`http://localhost:4000/api/pulse/get/${this.state.listingId}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
      this.setState({ settingsModalShow: false, customizationsModalShow: false });
      // alert(
      //   "Changes have been successfully submitted!"
      // );
      this.refreshPage()
    // await this.props.history.push(`/calendar/${this.state.listingData.id}`);
  };

  render() {
    // console.log(this.state.reviews);
    const authToken = localStorage.getItem(AUTH_TOKEN);
    if (authToken) {
      return (
        <React.Fragment>
          <Modal
            size="md"
            show={this.state.settingsModalShow}
            onHide={(e) => this.hideAlert(e)}
            name="settingsModalShow"
            aria-labelledby="modalSettings"
          >
            <Modal.Header closeButton>
              <Modal.Title id="modalSettings">
                <strong>Listing Settings</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.handleSubmitSettings}>
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    Absolute Pricing Range
                  </b>
                </h5>
                <FormGroup>
                  <Row>
                    <Col lg={4}>
                      <Form.Label>Base Price ($)</Form.Label>
                      <Form.Control
                        name="BasePrice"
                        onChange={this.handleChange}
                        placeholder={
                          this.state.unitData[0] === undefined
                            ? 0
                            : this.state.unitData[0].basePrice
                        }
                      ></Form.Control>
                    </Col>
                    <Col lg={4}>
                      <Form.Label>Min Price ($)</Form.Label>
                      <Form.Control
                        name="MinPrice"
                        onChange={this.handleChange}
                        placeholder={
                          this.state.unitData[0] === undefined
                            ? 0
                            : this.state.unitData[0].minPrice
                        }
                      ></Form.Control>
                    </Col>
                    <Col lg={4}>
                      <Form.Label>Max Price ($)</Form.Label>
                      <Form.Control
                        name="MaxPrice"
                        onChange={this.handleChange}
                        placeholder={
                          this.state.unitData[0] === undefined
                            ? 0
                            : this.state.unitData[0].maxPrice
                        }
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Monthly Base Price"
                      checked={this.state.monthlyBasePrice}
                      name="monthlyBasePrice"
                      id="monthlyBasePrice"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <FormGroup
                  className={this.state.monthlyBasePrice ? "" : "blurry-text"}
                >
                  <Row>
                    <Col lg={2}>
                      <Form.Label>Jan</Form.Label>
                      <Form.Control
                        name="Jan"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      />
                    </Col>
                    <Col lg={2}>
                      <Form.Label>Feb</Form.Label>
                      <Form.Control
                        name="Feb"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col lg={2}>
                      <Form.Label>Mar</Form.Label>
                      <Form.Control
                        name="Mar"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      />
                    </Col>
                    <Col lg={2}>
                      <Form.Label>Apr</Form.Label>
                      <Form.Control
                        name="Apr"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col lg={2}>
                      <Form.Label>May</Form.Label>
                      <Form.Control
                        name="May"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      />
                    </Col>
                    <Col lg={2}>
                      <Form.Label>Jun</Form.Label>
                      <Form.Control
                        name="Jun"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup
                  className={this.state.monthlyBasePrice ? "" : "blurry-text"}
                >
                  <Row>
                    <Col lg={2}>
                      <Form.Label>Jul</Form.Label>
                      <Form.Control
                        name="Jul"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      />
                    </Col>
                    <Col lg={2}>
                      <Form.Label>Aug</Form.Label>
                      <Form.Control
                        name="Aug"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col lg={2}>
                      <Form.Label>Sep</Form.Label>
                      <Form.Control
                        name="Sep"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      />
                    </Col>
                    <Col lg={2}>
                      <Form.Label>Oct</Form.Label>
                      <Form.Control
                        name="Oct"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col lg={2}>
                      <Form.Label>Nov</Form.Label>
                      <Form.Control
                        name="Nov"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      />
                    </Col>
                    <Col lg={2}>
                      <Form.Label>Dec</Form.Label>
                      <Form.Control
                        name="Dec"
                        onChange={this.handleChange}
                        disabled={this.state.monthlyBasePrice ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Track Comp Listings"
                      checked={this.state.tcl}
                      name="tcl"
                      id="tcl"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <Row>
                  <Col lg={{ span: 10, offset: 1 }}>
                    {/* <Form.Label>Add Airbnb Listing ID</Form.Label> */}
                    <Form.Control
                      type="text"
                      name="tclAddListing"
                      onChange={this.handleChange}
                      disabled={this.state.tcl ? false : true}
                      placeholder="AirBnb Listing ID"
                    ></Form.Control>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                  <Col xs={12} lg={{ span: 10, offset: 1 }}>
                    <span className={this.state.tcl ? "" : "blurry-text"}>
                      👋 Hello there - Coming soon! 🚧
                    </span>
                  </Col>
                </Row>
                <Row style={{ marginTop: "2rem" }}>
                  <Col>
                    <Form.Control
                      type="submit"
                      value="Submit Changes"
                      variant="outline-info ml-4"
                      className="bg-custom-1"
                      style={{ color: "white" }}
                    />
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            size="md"
            // style={{ maxHeight: "85vh", overflowY: "scroll" }}
            show={this.state.customizationsModalShow}
            onHide={(e) => this.hideAlert(e)}
            name="customizationsModalShow"
            aria-labelledby="modalCustomizations"
          >
            <Modal.Header closeButton>
              <Modal.Title id="modalCustomizations">
                <strong>Listing Customizations</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Last Minute Pricing"
                      checked={this.state.lmp}
                      name="lmp"
                      id="lmp"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.lmp ? "" : "blurry-text"}
                      >
                        Type
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="lmpType"
                        onChange={this.handleChange}
                        disabled={this.state.lmp ? false : true}
                      >
                        <option default value="none">
                          -
                        </option>
                        <option value="flat">Flat (%)</option>
                        <option value="gradual">Gradual (%)</option>
                        <option value="fixed">Fixed ($)</option>
                      </Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.lmp ? "" : "blurry-text"}
                      >
                        Amount
                      </Form.Label>
                      <Form.Control
                        name="lmpAmount"
                        onChange={this.handleChange}
                        disabled={this.state.lmp ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.lmp ? "" : "blurry-text"}
                      >
                        Within (X) Days
                      </Form.Label>
                      <Form.Control
                        name="lmpLeadTime"
                        onChange={this.handleChange}
                        disabled={this.state.lmp ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Orphan Day Pricing"
                      checked={this.state.odp}
                      name="odp"
                      id="odp"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.odp ? "" : "blurry-text"}
                      >
                        Type
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="odpType"
                        onChange={this.handleChange}
                        disabled={this.state.odp ? false : true}
                      >
                        <option default value="none">
                          -
                        </option>
                        <option value="flat">Flat (%)</option>
                        <option value="gradual">Gradual (%)</option>
                        <option value="fixed">Fixed ($)</option>
                      </Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.odp ? "" : "blurry-text"}
                      >
                        Amount
                      </Form.Label>
                      <Form.Control
                        name="odpAmount"
                        onChange={this.handleChange}
                        disabled={this.state.odp ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.odp ? "" : "blurry-text"}
                      >
                        Gaps of (X) Days
                      </Form.Label>
                      <Form.Control
                        name="opdLeadTime"
                        onChange={this.handleChange}
                        disabled={this.state.odp ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Minimum Far Out Pricing"
                      checked={this.state.mfop}
                      name="mfop"
                      id="mfop"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.mfop ? "" : "blurry-text"}
                      >
                        Type
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="mfopType"
                        onChange={this.handleChange}
                        disabled={this.state.mfop ? false : true}
                      >
                        <option default value="none">
                          -
                        </option>
                        <option value="flat">Flat (%)</option>
                        <option value="gradual">Gradual (%)</option>
                        <option value="fixed">Fixed ($)</option>
                      </Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.mfop ? "" : "blurry-text"}
                      >
                        Amount
                      </Form.Label>
                      <Form.Control
                        name="mfopAmount"
                        onChange={this.handleChange}
                        disabled={this.state.mfop ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.mfop ? "" : "blurry-text"}
                      >
                        Within (X) Days
                      </Form.Label>
                      <Form.Control
                        name="mfopLeadTime"
                        onChange={this.handleChange}
                        disabled={this.state.mfop ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Minimum Night Stay Update"
                      checked={this.state.msu}
                      name="msu"
                      id="msu"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.msu ? "" : "blurry-text"}
                      >
                        Last Minute
                      </Form.Label>
                      <Form.Control
                        name="lmMinNightStay"
                        onChange={this.handleChange}
                        placeholder="# of night(s)"
                        disabled={this.state.msu ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.msu ? "" : "blurry-text"}
                      >
                        Orphan Day(s)
                      </Form.Label>
                      <Form.Control
                        name="odMinNightStay"
                        onChange={this.handleChange}
                        placeholder="# of night(s)"
                        disabled={this.state.msu ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.msu ? "" : "blurry-text"}
                      >
                        Far Out Days
                      </Form.Label>
                      <Form.Control
                        name="foMinNightStay"
                        onChange={this.handleChange}
                        placeholder="# of night(s)"
                        disabled={this.state.msu ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Day of Week Adjustment"
                      checked={this.state.dowa}
                      name="dowa"
                      id="dowa"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Type
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="dowaType"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      >
                        <option default value="none">
                          -
                        </option>
                        <option value="flat">Flat (%)</option>
                        <option value="gradual">Gradual (%)</option>
                        <option value="fixed">Fixed ($)</option>
                      </Form.Control>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12} lg={{ span: 2, offset: 2 }}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Mon
                      </Form.Label>
                      <Form.Control
                        name="dowaM"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Tue
                      </Form.Label>
                      <Form.Control
                        name="dowaT"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Wed
                      </Form.Label>
                      <Form.Control
                        name="dowaW"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Thu
                      </Form.Label>
                      <Form.Control
                        name="dowaTh"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "0.5rem" }}>
                    <Col xs={12} lg={{ span: 2, offset: 3 }}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Fri
                      </Form.Label>
                      <Form.Control
                        name="dowaF"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Sat
                      </Form.Label>
                      <Form.Control
                        name="dowaSa"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Sun
                      </Form.Label>
                      <Form.Control
                        name="dowaS"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <Row style={{ marginTop: "2rem" }}>
                  <Col>
                    <Form.Control
                      type="submit"
                      value="Submit Changes"
                      variant="outline-info ml-4"
                      className="bg-custom-1"
                      style={{ color: "white" }}
                    />
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
          <Container style={{ marginTop: "2em", marginBottom: "2em" }}>
            <h1>
              Listing Overview{" "}
              {this.state.unitData.length > 0 ? (
                <>
                  <Button
                    as={Link}
                    to={"/performance/" + this.state.listingData.id}
                    variant="outline-info ml-4"
                    className="bg-custom-1"
                    style={{ color: "white" }}
                  >
                    View Performance
                  </Button>
                  <Button
                    onClick={(e) => this.showAlert(e)}
                    name="settingsModalShow"
                    variant="warning ml-2"
                    className="warning"
                    // style={{ color: "white" }}
                  >
                    Settings
                  </Button>
                  <Button
                    onClick={(e) => this.showAlert(e)}
                    name="customizationsModalShow"
                    variant="secondary ml-2"
                    className="secondary"
                    // style={{ color: "white" }}
                  >
                    Customizations
                  </Button>
                </>
              ) : (
                ""
              )}
            </h1>
            <div className="card mb-1" style={{ marginTop: "2rem" }}>
              <div className="row no-gutters">
                <div className="col-md-3 mt-2 mb-4 ml-4 mr-4">
                  <img
                    src={this.state.listingData.picture_url}
                    onError={(e) => this.onImgErrorSmall(e)}
                    className="card-img mt-4 mb-2 ml-2 mr-2 ab"
                    alt={this.state.listingData.host_name}
                  />
                  <center>
                    <b>Location: </b>
                    {this.state.listingData.neighbourhood}
                    <hr />
                    <b>
                      👥 - {this.state.listingData.accommodates} | 🚪 -{" "}
                      {this.state.listingData.bedrooms} | 🛏️ -{" "}
                      {this.state.listingData.beds} | 🛁 -{" "}
                      {this.state.listingData.bathrooms}
                    </b>
                  </center>
                </div>
                <div className="col-md-6 mt-4 mb-2 ml-2 mr-2">
                  <div className="card-body">
                    <h2 className="card-title cardstyle">
                      <b>{this.state.listingData.name}</b>
                    </h2>
                    <p
                      className="card-text"
                      style={{ height: "12vh", overflowY: "scroll" }}
                    >
                      {ReactHtmlParser(this.state.listingData.description)}
                    </p>
                    <p className="card-text">
                      <Button
                        as={Link}
                        to={"/calendar/" + this.state.listingData.id}
                        variant="outline-warning"
                      >
                        🗓
                      </Button>
                      <Button
                        as={Link}
                        to={"/events/" + this.state.listingData.id}
                        variant="outline-info ml-2"
                      >
                        Pricing List
                      </Button>

                      <Button
                        variant="outline-danger ml-2"
                        className=""
                        href={this.state.listingData.listing_url}
                        target="_blank"
                      >
                        View Listing
                      </Button>

                      {/* <Button variant="outline-primary" className="ml-2">
                        Add to CompSet
                      </Button> */}
                    </p>
                  </div>
                </div>
                <div className="col-md-2 mt-4 mb-2 ml-2 mr-2">
                  <center>
                    <img
                      src={this.state.listingData.host_picture_url}
                      onError={(e) => this.onImgErrorSmall(e)}
                      className="card-img abc mt-4"
                      alt={this.state.listingData.host_name}
                    />

                    <h3 className="cardstyle">
                      {this.state.listingData.host_name}{" "}
                    </h3>
                    <small className="text-muted">
                      <a
                        href={this.state.listingData.host_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Host Data
                      </a>{" "}
                      |{" "}
                      <a
                        href={this.state.listingData.host_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Host
                      </a>
                      <br />
                      <span className="badge badge-outline-dark badge-pill">
                        {this.state.listingData.host_id}
                      </span>
                    </small>
                  </center>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{ paddingTop: "10px", paddingLeft: "15px" }}
            >
              <ul className="list-group col-lg-4 col-md-4 col-sm-12">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Review Count
                  <span className="badge badge-primary badge-pill">
                    {this.state.listingData.number_of_reviews !== null
                      ? this.state.listingData.number_of_reviews
                      : "No Reviews"}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  ADR
                  <span className="badge badge-warning badge-pill">
                    {this.pulseADR().toFixed(2) === "NaN"
                      ? this.state.listingData.price
                      : "$" + this.pulseADR().toFixed(2)}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Rating
                  <span className="badge badge-info badge-pill">
                    {this.state.listingData.review_scores_rating}
                  </span>
                </li>
              </ul>
              <ul className="list-group col-lg-4 col-md-4 col-sm-12">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  7-Day Occupancy
                  <span
                    className={
                      "badge badge-" +
                      (this.mthdayOcc(7) >= 80
                        ? "success"
                        : this.mthdayOcc(7) >= 60
                        ? "warning"
                        : "danger") +
                      " badge-pill"
                    }
                  >
                    {this.mthdayOcc(7)}%
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  15-Day Occupancy
                  <span
                    className={
                      "badge badge-" +
                      (this.mthdayOcc(15) >= 75
                        ? "success"
                        : this.mthdayOcc(15) >= 60
                        ? "warning"
                        : "danger") +
                      " badge-pill"
                    }
                  >
                    {this.mthdayOcc(15)}%
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  30-Day Occupancy
                  <span
                    className={
                      "badge badge-" +
                      (this.mthdayOcc(30) >= 75
                        ? "success"
                        : this.mthdayOcc(30) >= 60
                        ? "warning"
                        : "danger") +
                      " badge-pill"
                    }
                  >
                    {this.mthdayOcc(30)}%
                  </span>
                </li>
              </ul>
              <ul className="list-group col-lg-4 col-md-4 col-sm-12">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  60-Day Occupancy
                  <span
                    className={
                      "badge badge-" +
                      (this.mthdayOcc(60) >= 70
                        ? "success"
                        : this.mthdayOcc(60) >= 50
                        ? "primary"
                        : "warning") +
                      " badge-pill"
                    }
                  >
                    {this.mthdayOcc(60)}%
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  90-Day Occupancy
                  <span
                    className={
                      "badge badge-" +
                      (this.mthdayOcc(90) >= 70
                        ? "success"
                        : this.mthdayOcc(90) >= 50
                        ? "primary"
                        : "warning") +
                      " badge-pill"
                    }
                  >
                    {this.mthdayOcc(90)}%
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  180-Day Occupancy
                  <span
                    className={
                      "badge badge-" +
                      (this.mthdayOcc(180) >= 65
                        ? "success"
                        : this.mthdayOcc(180) >= 45
                        ? "warning"
                        : "info") +
                      " badge-pill"
                    }
                  >
                    {this.mthdayOcc(180)}%
                  </span>
                </li>
              </ul>
            </div>
            <hr />
            <Row style={{ marginTop: "1.5rem" }}>
              <Col xs={12}>
                {this.state.reviews.length > 0 ? (
                  <h3>Latest Reviews</h3>
                ) : (
                  <blockquote>No reviews for this listing</blockquote>
                )}
              </Col>
            </Row>
            <Row
              style={{
                marginLeft: "1.5rem",
                height: "75vh",
                overflowY: "scroll",
              }}
            >
              {this.reviewList()}
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default ListingOverview;
