import React, { Component, Fragment } from 'react';
import { Navbar, Nav, NavDropdown, Button, Form } from "react-bootstrap";
import { Link }  from "react-router-dom";
import { AUTH_TOKEN } from "../constants";
import Gear from "../img/settings.png";
// import logo from "../img/quibble-logo.png";
import qrm from "../img/quibbleRMlogo2.png"
import actions from "../services/index";



class Navigation extends Component {
  
  async componentDidMount() {
    let user = await actions.isLoggedIn();
    this.setState({ ...user.data });
    // console.log("Current User >> ", user);
  }

  logOut = () => {
    // e.preventDefault()
    // console.log("triggering logOut");
    window.localStorage.clear(AUTH_TOKEN);
    window.location.href = "/";
    // this.props.history.push("/login")
    // console.log(this.state)
  };

  render() {
    // console.log("STATE" ,this.state);
    // console.log("PROPS", this.props);
    const authToken = localStorage.getItem(AUTH_TOKEN);
    return (
      <Navbar
        // className="color-nav"
        variant="light"
        expand="lg"
        sticky="top"
        className="bg-custom-nav color-nav"
      >
        <Navbar.Brand as={Link} to="/home">
          <img
            src={qrm}
            width="150"
            className="d-inline-block align-top"
            alt="QuibbleRM"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {authToken ? (
              <Fragment>
                <Nav.Link as={Link} to="/hub">
                  Analytics
                </Nav.Link>
                <Nav.Link as={Link} to="/units">
                  Properties
                </Nav.Link>
                {/* <Nav.Link as={Link} to="/market">
                  Markets
                </Nav.Link> */}
                <NavDropdown
                  alignRight
                  title={"Manage"}
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item as={Link} to="/">
                    Account Settings
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/forecast">
                    Forecast
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/rules">
                    Rules
                  </NavDropdown.Item>
                </NavDropdown>
                {/* <Nav.Link href="/import">Imports</Nav.Link> */}
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </Nav>

          {authToken ? (
            <Fragment>
              <NavDropdown
                alignRight
                title={"qRM Access"}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/qRMaccess/clients">
                  Clients
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/qRMaccess/hostdata">
                  Host Data
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/qRMaccess/search">
                  Data WH
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/qRMaccess/$crapingQ">
                  $craping-Q
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown alignRight title={"Admin"} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/qRMaccess/users">
                  Users
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/qRMaccess/integrations">
                  Integrations
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                alignRight
                title={
                  <img
                    className="thumbnail-image"
                    src={Gear}
                    alt="settings"
                    height="25"
                  />
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/qRMaccess/account">
                  My Account
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/qRMaccess/gCustom">
                  Group Customizations
                </NavDropdown.Item>
              </NavDropdown>
              <Form inline>
                <Button
                  onClick={this.logOut}
                  variant="danger ml-2"
                  className="btn"
                >
                  Log Out
                </Button>
              </Form>
            </Fragment>
          ) : (
            <Fragment>
              <Form inline>
                <Button as={Link} to="/login" variant="outline-info mr-1">
                  Login
                </Button>
              </Form>
            </Fragment>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;