import React, { Component } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";
import "bootstrap/js/src/collapse.js";
// import { Bar } from "react-chartjs-2";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL = "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

class ListingPerformance extends Component {
  state = {
    reservations: [],
    showModalEvent: false,
    activeRes: [],
    activeMonthlyRes: [],
    activePrevYrMonthlyResUTD: [],
    activePrevYearlyRes: [],
    activePrevYrMonthlyRes: [],
    activePrevYrResUTD: [],
  };

  componentDidMount() {
    let user = actions.isLoggedIn();
    this.setState({ ...user.data });

    axios
      .get(
        `${baseURL}/api/reservation/activeYearlyRes/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activeRes: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/reservation/activePrevYearlyRes/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activePrevYrRes: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/reservation/activePrevYearlyResUTD/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activePrevYrResUTD: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/reservation/activeMonthlyRes/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activeMonthlyRes: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/reservation/activePrevYrMonthlyResUTD/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activePrevYrMonthlyResUTD: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/reservation/activePrevYrMonthlyRes/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activePrevYrMonthlyRes: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  occNights = (arr) => {
    let totalNights = 0;
    // let arr = this.state.activeRes;
    //   for (let i = 0; i < arr.length; i++) {
    let counter = 0;
    arr.forEach((booking) => {
      if (
        booking.user_facing_status_localized === "Confirmed" ||
        booking.user_facing_status_localized === "reserved"
      ) {
        counter += Number(booking.nights);
        // console.log(counter)
      }
    });
    totalNights = counter;
    return totalNights;
  };

  netRevenue = (arr) => {
    let totalRevenue = 0;
    // let arr = this.state.activeRes;
    //   for (let i = 0; i < arr.length; i++) {
    let counter = 0;
    arr.forEach((booking) => {
      if (
        booking.user_facing_status_localized === "Confirmed" ||
        booking.user_facing_status_localized === "reserved"
      ) {
        counter += Number(booking.earnings.replace(/[,|$]/g, ""));
        // console.log(counter);
      }
    });
    totalRevenue = counter;
    return totalRevenue;
  };

  aveLOS = (arr) => {
    let averageLOS = 0;
    let bookings = 0;
    // let arr = this.state.activeRes;
    //   for (let i = 0; i < arr.length; i++) {
    let counter = 0;
    arr.forEach((booking) => {
      if (
        booking.user_facing_status_localized === "Confirmed" ||
        booking.user_facing_status_localized === "reserved"
      ) {
        counter += booking.nights;
        bookings++;
        // console.log(counter);
      }
    });
    averageLOS = counter / bookings;
    // console.log(bookings, counter)
    return averageLOS;
  };

  handleCardClick = (e) => {
    console.log(e.target.id);
  };

  render() {
    console.log(this.state)
    //    let data2 = {
    //      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    //      datasets: [
    //        {
    //          label: "# of Red Votes",
    //          data: [12, 19, 3, 5, 2, 3],
    //          backgroundColor: "rgb(255, 99, 132)",
    //        },
    //        // {
    //        //   label: "# of Blue Votes",
    //        //   data: [2, 3, 20, 5, 1, 4],
    //        //   backgroundColor: "rgb(54, 162, 235)",
    //        // },
    //        // {
    //        //   label: "# of Green Votes",
    //        //   data: [3, 10, 13, 15, 22, 30],
    //        //   backgroundColor: "rgb(75, 192, 192)",
    //        // },
    //      ],
    //    };

    // let netRev = {
    //   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    //   datasets: [
    //     {
    //       label: "$ - Revenue",
    //       data: this.state.activeRes.map((reservations) => {
    //         // let jan = ["Jan", 0];
    //         // let feb = ["Feb", 0];
    //         // let mar = ["Mar", 0];
    //         // let apr = ["Apr", 0];
    //         // let may = ["May", 0];
    //         // let jun = ["Jun", 0];
    //         // let jul = ["Jul", 0];
    //         // let aug = ["Aug", 0];
    //         // let sep = ["Sep", 0];
    //         // let oct = ["Oct", 0];
    //         // let nov = ["Nov", 0];
    //         // let dec = ["Dec", 0];
    //         let jan = this.state.activeRes.map(reservations => {
    //           let month = 0;
    //           if (
    //             reservations.start_date.slice(0, 7) === "2021-01" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             month += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           } 
    //           return month
    //         });
    //         let feb = this.state.activeRes.map((reservations) => {
    //           let month = 0;
    //           if (
    //             reservations.start_date.slice(0, 7) === "2021-02" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             month += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           }
    //           return month;
    //         });
    //         let mar = 0;
    //         let apr = 0;
    //         let may = 0;
    //         let jun = 0;
    //         let jul = 0;
    //         let aug = 0;
    //         let sep = 0;
    //         let oct = 0;
    //         let nov = 0;
    //         let dec = 0;
    //         let arr = [];
    //         for (let i=0; i<this.state.activeRes.length; i++) {

    //             if (
    //             reservations.start_date.slice(0, 7) === "2021-03" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             mar += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           } else if (
    //             reservations.start_date.slice(0, 7) === "2021-04" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             apr += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           } else if (
    //             reservations.start_date.slice(0, 7) === "2021-05" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             may += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           } else if (
    //             reservations.start_date.slice(0, 7) === "2021-06" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             jun += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           } else if (
    //             reservations.start_date.slice(0, 7) === "2021-07" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             jul += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           } else if (
    //             reservations.start_date.slice(0, 7) === "2021-08" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             aug += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           } else if (
    //             reservations.start_date.slice(0, 7) === "2021-09" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             sep += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           } else if (
    //             reservations.start_date.slice(0, 7) === "2021-10" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             oct += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           } else if (
    //             reservations.start_date.slice(0, 7) === "2021-11" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             nov += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           } else if (
    //             reservations.start_date.slice(0, 7) === "2021-12" &&
    //             (reservations.user_facing_status_localized === "Confirmed" ||
    //               reservations.user_facing_status_localized === "reserved")
    //           ) {
    //             dec += Number(reservations.earnings.replace(/[,|$]/g, ""));
    //           }
    //           arr = [
    //             jan,
    //             feb,
    //             mar,
    //             apr,
    //             may,
    //             jun,
    //             jul,
    //             aug,
    //             sep,
    //             oct,
    //             nov,
    //             dec,
    //           ];
    //         }
            
    //         // arr.push(
    //         //   jan,
    //         //   feb,
    //         //   mar,
    //         //   apr,
    //         //   may,
    //         //   jun,
    //         //   jul,
    //         //   aug,
    //         //   sep,
    //         //   oct,
    //         //   nov,
    //         //   dec
    //         // );
    //         // console.log(arr);
    //         return arr;
    //       }),
    //       backgroundColor: "rgb(48, 190, 213)",
    //     },
    //     // {
    //     //   label: "# of Blue Votes",
    //     //   data: [2, 3, 20, 5, 1, 4],
    //     //   backgroundColor: "rgb(54, 162, 235)",
    //     // },
    //     // {
    //     //   label: "# of Green Votes",
    //     //   data: [3, 10, 13, 15, 22, 30],
    //     //   backgroundColor: "rgb(75, 192, 192)",
    //     // },
    //   ],
    // };

    // let bookedNights = {
    //   labels: [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ],
    //   datasets: [
    //     {
    //       label: "Count of Nights",
    //       data: this.state.activeRes.map((reservations) => {
    //         let jan = 0;
    //         let feb = 0;
    //         let arr = [];
    //         if (
    //           reservations.start_date.slice(0, 7) === "2021-01" &&
    //           reservations.user_facing_status_localized === "Confirmed"
    //         ) {
    //           jan += Number(reservations.earnings.slice(1).replace(/,/g, ""));
    //           //  console.log(jan);
    //         }
    //         if (
    //           reservations.start_date.slice(0, 7) === "2021-02" &&
    //           reservations.user_facing_status_localized === "Confirmed"
    //         ) {
    //           feb += Number(reservations.earnings.slice(1).replace(/,/g, ""));
    //           //  console.log(feb);
    //         }
    //         arr.push(jan, feb);
    //         //  console.log(arr);
    //         return arr;
    //       }),
    //       backgroundColor: "rgb(243, 192, 82)",
    //     },
    //   ],
    // };

    // let netADR = {
    //   labels: [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ],
    //   datasets: [
    //     {
    //       label: "$ - Revenue",
    //       data: this.state.activeRes.map((reservations) => {
    //         let jan = 0;
    //         let feb = 0;
    //         let arr = [];
    //         if (
    //           reservations.start_date.slice(0, 7) === "2021-01" &&
    //           reservations.user_facing_status_localized === "Confirmed"
    //         ) {
    //           jan += Number(reservations.earnings.slice(1).replace(/,/g, ""));
    //           //  console.log(jan);
    //         }
    //         if (
    //           reservations.start_date.slice(0, 7) === "2021-02" &&
    //           reservations.user_facing_status_localized === "Confirmed"
    //         ) {
    //           feb += Number(reservations.earnings.slice(1).replace(/,/g, ""));
    //           //  console.log(feb);
    //         }
    //         arr.push(jan, feb);
    //         //  console.log(arr);
    //         return arr;
    //       }),
    //       backgroundColor: "rgb(255, 99, 132)",
    //     },
    //   ],
    // };

    // let reservations = {
    //   labels: [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ],
    //   datasets: [
    //     {
    //       label: "Reservation Count",
    //       data: this.state.activeRes.map((reservations) => {
    //         let jan = 0;
    //         let feb = 0;
    //         let arr = [];
    //         if (
    //           reservations.start_date.slice(0, 7) === "2021-01" &&
    //           reservations.user_facing_status_localized === "Confirmed"
    //         ) {
    //           jan += Number(reservations.earnings.slice(1).replace(/,/g, ""));
    //           //  console.log(jan);
    //         }
    //         if (
    //           reservations.start_date.slice(0, 7) === "2021-02" &&
    //           reservations.user_facing_status_localized === "Confirmed"
    //         ) {
    //           feb += Number(reservations.earnings.slice(1).replace(/,/g, ""));
    //           //  console.log(feb);
    //         }
    //         arr.push(jan, feb);
    //         //  console.log(arr);
    //         return arr;
    //       }),
    //       backgroundColor: "rgb(95, 38, 132)",
    //     },
    //   ],
    // };

    // let aveLOS = {
    //   labels: [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ],
    //   datasets: [
    //     {
    //       label: "Number of Days",
    //       data: this.state.activeRes.map((reservations) => {
    //         let jan = 0;
    //         let feb = 0;
    //         let arr = [];
    //         if (
    //           reservations.start_date.slice(0, 7) === "2021-01" &&
    //           reservations.user_facing_status_localized === "Confirmed"
    //         ) {
    //           jan += Number(reservations.earnings.slice(1).replace(/,/g, ""));
    //           //  console.log(jan);
    //         }
    //         if (
    //           reservations.start_date.slice(0, 7) === "2021-02" &&
    //           reservations.user_facing_status_localized === "Confirmed"
    //         ) {
    //           feb += Number(reservations.earnings.slice(1).replace(/,/g, ""));
    //           //  console.log(feb);
    //         }
    //         arr.push(jan, feb);
    //         //  console.log(arr);
    //         return arr;
    //       }),
    //       backgroundColor: "rgb(8, 9, 132)",
    //     },
    //   ],
    // };

    // let netATV = {
    //   labels: [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ],
    //   datasets: [
    //     {
    //       label: "$ - Revenue",
    //       data: this.state.activeRes.map((reservations) => {
    //         let jan = 0;
    //         let feb = 0;
    //         let arr = [];
    //         if (
    //           reservations.start_date.slice(0, 7) === "2021-01" &&
    //           reservations.user_facing_status_localized === "Confirmed"
    //         ) {
    //           jan += Number(reservations.earnings.slice(1).replace(/,/g, ""));
    //           //  console.log(jan);
    //         }
    //         if (
    //           reservations.start_date.slice(0, 7) === "2021-02" &&
    //           reservations.user_facing_status_localized === "Confirmed"
    //         ) {
    //           feb += Number(reservations.earnings.slice(1).replace(/,/g, ""));
    //           //  console.log(feb);
    //         }
    //         arr.push(jan, feb);
    //         //  console.log(arr);
    //         return arr;
    //       }),
    //       backgroundColor: "rgb(28, 148, 32)",
    //     },
    //   ],
    // };

    // let options2 = {
    //   scales: {
    //     yAxes: [
    //       {
    //         ticks: {
    //           beginAtZero: true,
    //         },
    //       },
    //     ],
    //   },
    // };
    // console.log(this.state.activeRes, this.state.activeMonthlyRes);
    // console.log(this.occNights());
    const authToken = localStorage.getItem(AUTH_TOKEN);
    if (authToken) {
      return (
        <React.Fragment>
          <Row
            style={{
              marginBottom: "2em",
              marginLeft: "1.5rem",
              marginTop: "2rem",
            }}
          >
            <Col xs={12}>
              <h1>
                Listing Performance{" "}
                <Button
                  as={Link}
                  to={"/listing/" + this.props.match.params.id}
                  variant="outline-dark ml-4"
                >
                  Overview
                </Button>
                <Button
                  as={Link}
                  to={"/calendar/" + this.props.match.params.id}
                  variant="outline-warning ml-2"
                >
                  🗓
                </Button>
                <Button
                  as={Link}
                  to={"/events/" + this.props.match.params.id}
                  variant="outline-info ml-2"
                >
                  Pricing List
                </Button>
                <Button
                  as={Link}
                  to={"/reservations/" + this.props.match.params.id}
                  variant="primary ml-2"
                >
                  Reservations
                </Button>
              </h1>
            </Col>
          </Row>
          <Container
            style={{ marginTop: "2em", height: "100%", overflowY: "scroll" }}
          >
            <h5>Month to Date</h5>
            <Row
              style={{ marginBottom: "3em", overflowX: "scroll" }}
              className="center2"
            >
              <div
                id="monthlyRev"
                name="monthlyRev"
                onClick={(e) => this.handleCardClick(e)}
              >
                <Card
                  className="bg-custom-4"
                  text="dark"
                  variant="info"
                  style={{
                    width: "9.5rem",
                    marginTop: "2rem",
                    marginLeft: "2rem",
                  }}
                >
                  {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                  <Card.Body>
                    <Card.Title style={{ textAlign: "center" }}>
                      $
                      {this.netRevenue(this.state.activeMonthlyRes)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Card.Title>
                    <Card.Text
                      style={{ textAlign: "center", fontSize: "0.9em" }}
                    >
                      <b>Net Revenue</b>
                    </Card.Text>

                    <div
                      style={{
                        backgroundColor: "whitesmoke",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.001rem",
                        borderRadius: "10px",
                        paddingLeft: "0.3rem",
                        paddingRight: "0.3rem",
                      }}
                    >
                      <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                        <b>Benchmarks</b>
                      </h6>
                      <blockquote
                        style={{ fontSize: "0.5em", textAlign: "center" }}
                      >
                        <b>Last Yr UTD:{"  "}</b> $
                        {this.netRevenue(this.state.activePrevYrMonthlyResUTD)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        <br />
                        <b>Gap:{"  "}</b> $
                        {(
                          this.netRevenue(this.state.activeMonthlyRes) -
                          this.netRevenue(this.state.activePrevYrMonthlyResUTD)
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        <hr />
                        <h6 style={{ fontSize: "1em" }}>
                          <i>Full Month Data</i>
                        </h6>
                        <b>Last Year:{"  "}</b> $
                        {this.netRevenue(this.state.activePrevYrMonthlyRes)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        <br />
                        <b>Y-o-Y:{"  "}</b>$
                        {(
                          this.netRevenue(this.state.activeMonthlyRes) -
                          this.netRevenue(this.state.activePrevYrMonthlyRes)
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </blockquote>
                      {/* <Button name="monthlyRev" id="monthlyRev" onClick={(e)=>this.handleCardClick(e)} block variant="primary">Analytics</Button> */}
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {this.occNights(this.state.activeMonthlyRes)}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Booked Nights</b>
                  </Card.Text>
                  {/* <hr /> */}
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                      paddingLeft: "0.3rem",
                      paddingRight: "0.3rem",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b>
                      {this.occNights(this.state.activePrevYrMonthlyResUTD)}
                      <br />
                      <b>Gap:{"  "}</b>{" "}
                      {this.occNights(this.state.activeMonthlyRes) -
                        this.occNights(this.state.activePrevYrMonthlyResUTD) >
                      0
                        ? "+"
                        : ""}
                      {this.occNights(this.state.activeMonthlyRes) -
                        this.occNights(this.state.activePrevYrMonthlyResUTD)}
                      <hr />
                      <h6 style={{ fontSize: "1em" }}>
                        <i>Full Month Data</i>
                      </h6>
                      <b>Last Year:{"  "}</b>
                      {this.occNights(this.state.activePrevYrMonthlyRes)}
                      <br />
                      <b>Y-o-Y:{"  "}</b>
                      {this.occNights(this.state.activeMonthlyRes) -
                        this.occNights(this.state.activePrevYrMonthlyRes) >
                      0
                        ? "+"
                        : ""}
                      {this.occNights(this.state.activeMonthlyRes) -
                        this.occNights(this.state.activePrevYrMonthlyRes)}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    $
                    {this.netRevenue(this.state.activeMonthlyRes) !== 0
                      ? (
                          Number(
                            this.netRevenue(
                              this.state.activeMonthlyRes
                            ).toFixed(2)
                          ) /
                          Number(this.occNights(this.state.activeMonthlyRes))
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "0.00"}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net ADR</b>
                  </Card.Text>
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                      paddingLeft: "0.3rem",
                      paddingRight: "0.3rem",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b> $
                      {this.occNights(this.state.activePrevYrMonthlyResUTD) !==
                      0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyResUTD
                              ).toFixed(2)
                            ) /
                            Number(
                              this.occNights(
                                this.state.activePrevYrMonthlyResUTD
                              )
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "0"}
                      <br />
                      <b>Gap:{"  "}</b> $
                      {this.netRevenue(this.state.activePrevYrMonthlyResUTD) !==
                      0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activeMonthlyRes
                              ).toFixed(2)
                            ) /
                              Number(
                                this.occNights(this.state.activeMonthlyRes)
                              ) -
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyResUTD
                              ).toFixed(2)
                            ) /
                              Number(
                                this.occNights(
                                  this.state.activePrevYrMonthlyResUTD
                                )
                              )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : this.netRevenue(this.state.activeMonthlyRes) !== 0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activeMonthlyRes
                              ).toFixed(2)
                            ) /
                            Number(this.occNights(this.state.activeMonthlyRes))
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "-" +
                          (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyResUTD
                              ).toFixed(2)
                            ) /
                            Number(
                              this.occNights(
                                this.state.activePrevYrMonthlyResUTD
                              )
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      <hr />
                      <h6 style={{ fontSize: "1em" }}>
                        <i>Full Month Data</i>
                      </h6>
                      <b>Last Year:</b> $
                      {this.occNights(this.state.activePrevYrMonthlyRes) !== 0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyRes
                              ).toFixed(2)
                            ) /
                            Number(
                              this.occNights(this.state.activePrevYrMonthlyRes)
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "0"}
                      <br />
                      <b>Y-o-Y:{"  "}</b>$
                      {this.netRevenue(this.state.activeMonthlyRes) !== 0 &&
                      this.netRevenue(this.state.activePrevYrMonthlyRes) !== 0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activeMonthlyRes
                              ).toFixed(2)
                            ) /
                              Number(
                                this.occNights(this.state.activeMonthlyRes)
                              ) -
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyRes
                              ).toFixed(2)
                            ) /
                              Number(
                                this.occNights(
                                  this.state.activePrevYrMonthlyRes
                                )
                              )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : this.netRevenue(this.state.activeMonthlyRes) !== 0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activeMonthlyRes
                              ).toFixed(2)
                            ) /
                            Number(this.occNights(this.state.activeMonthlyRes))
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "-" +
                          (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyRes
                              ).toFixed(2)
                            ) /
                            Number(
                              this.occNights(this.state.activePrevYrMonthlyRes)
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {this.state.activeMonthlyRes.length}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Reservations</b>
                  </Card.Text>
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                      paddingLeft: "0.3rem",
                      paddingRight: "0.3rem",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b>
                      {this.state.activePrevYrMonthlyResUTD.length}
                      <br />
                      <b>Gap:{"  "}</b>

                      {this.state.activeMonthlyRes.length -
                        this.state.activePrevYrMonthlyResUTD.length}
                      <hr />
                      <h6 style={{ fontSize: "1em" }}>
                        <i>Full Month Data</i>
                      </h6>
                      <b>Last Year:{"  "}</b>
                      {this.state.activePrevYrMonthlyRes.length}
                      <br />
                      <b>Y-o-Y:{"  "}</b>

                      {this.state.activeMonthlyRes.length -
                        this.state.activePrevYrMonthlyRes.length}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {this.aveLOS(this.state.activeMonthlyRes).toFixed(1)}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Average LOS</b>
                  </Card.Text>
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                      paddingLeft: "0.3rem",
                      paddingRight: "0.3rem",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b>
                      {!this.aveLOS(this.state.activePrevYrMonthlyResUTD)
                        ? "0"
                        : this.aveLOS(
                            this.state.activePrevYrMonthlyResUTD
                          ).toFixed(1)}
                      <br />
                      <b>Gap:{"  "}</b>

                      {!this.aveLOS(this.state.activePrevYrMonthlyResUTD) ||
                      !this.aveLOS(this.state.activeRes)
                        ? "n/a"
                        : (
                            Number(
                              this.aveLOS(this.state.activeMonthlyRes).toFixed(
                                1
                              )
                            ) -
                            Number(
                              this.aveLOS(
                                this.state.activePrevYrMonthlyResUTD
                              ).toFixed(1)
                            )
                          )
                            .toFixed(1)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      <hr />
                      <h6 style={{ fontSize: "1em" }}>
                        <i>Full Month Data</i>
                      </h6>
                      <b>Last Year:{"  "}</b>
                      {!this.aveLOS(this.state.activePrevYrMonthlyRes) ||
                      !this.aveLOS(this.state.activeRes)
                        ? "0"
                        : this.aveLOS(
                            this.state.activePrevYrMonthlyRes
                          ).toFixed(1)}
                      <br />
                      <b>Y-o-Y:{"  "}</b>

                      {!this.aveLOS(this.state.activePrevYrMonthlyRes) ||
                      !this.aveLOS(this.state.activeRes)
                        ? "n/a"
                        : (
                            Number(
                              this.aveLOS(this.state.activeMonthlyRes).toFixed(
                                1
                              )
                            ) -
                            Number(
                              this.aveLOS(
                                this.state.activePrevYrMonthlyRes
                              ).toFixed(1)
                            )
                          )
                            .toFixed(1)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    $
                    {(
                      Number(this.netRevenue(this.state.activeMonthlyRes)) /
                      this.state.activeMonthlyRes.length
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net ATV</b>
                  </Card.Text>
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                      paddingLeft: "0.3rem",
                      paddingRight: "0.3rem",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b> $
                      {this.state.activePrevYrMonthlyResUTD.length > 0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyResUTD
                              )
                            ) / this.state.activePrevYrMonthlyResUTD.length
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "0"}
                      <br />
                      <b>Gap:{"  "}</b>
                      {this.state.activePrevYrMonthlyResUTD.length === 0
                        ? "n/a"
                        : "$"+(
                            Number(
                              this.netRevenue(this.state.activeMonthlyRes) /
                                this.state.activeMonthlyRes.length
                            ) -
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyResUTD
                              ) / this.state.activePrevYrMonthlyResUTD.length
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      <hr />
                      <h6 style={{ fontSize: "1em" }}>
                        <i>Full Month Data</i>
                      </h6>
                      <b>Last Year:{"  "}</b>$
                      {this.state.activePrevYrMonthlyRes.length < 0
                        ? "$"+(
                            Number(
                              this.netRevenue(this.state.activePrevYrMonthlyRes)
                            ) / this.state.activePrevYrMonthlyRes.length
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "0"}{" "}
                      <br />
                      <b>Y-o-Y:{"  "}</b>
                      {this.state.activePrevYrMonthlyRes.length === 0
                        ? "n/a"
                        : "$"+(
                            Number(
                              this.netRevenue(this.state.activeMonthlyRes) /
                                this.state.activeMonthlyRes.length
                            ) -
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyRes
                              ) / this.state.activePrevYrMonthlyRes.length
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
            </Row>
            <h5>Year to Date</h5>
            <Row className="center2" style={{ marginBottom: "4rem" }}>
              <Card
                className="bg-custom-9"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    $
                    {this.netRevenue(this.state.activeRes)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net Revenue</b>
                  </Card.Text>
                  <hr />
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:</b> $
                      {this.netRevenue(this.state.activePrevYrResUTD)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      <br />
                      <b>Gap:{"  "}</b>$
                      {(
                        this.netRevenue(this.state.activeRes) -
                        this.netRevenue(this.state.activePrevYrResUTD)
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-9"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {this.occNights(this.state.activeRes)}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Booked Nights</b>
                  </Card.Text>
                  <hr />
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b>
                      {this.occNights(this.state.activePrevYrResUTD)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      <br />
                      <b>Gap:{"  "}</b>
                      {(
                        this.occNights(this.state.activeRes) -
                        this.occNights(this.state.activePrevYrResUTD)
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-9"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    $
                    {(
                      Number(this.netRevenue(this.state.activeRes).toFixed(2)) /
                      Number(this.occNights(this.state.activeRes))
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net ADR</b>
                  </Card.Text>
                  <hr />
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b>$
                      {this.occNights(this.state.activePrevYrResUTD) !== 0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrResUTD
                              ).toFixed(2)
                            ) /
                            Number(
                              this.occNights(this.state.activePrevYrResUTD)
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "0"}
                      <br />
                      <b>Gap:{"  "}</b> $
                      {this.netRevenue(this.state.activePrevYrResUTD) !== 0
                        ? (
                            Number(
                              this.netRevenue(this.state.activeRes).toFixed(2)
                            ) /
                              Number(this.occNights(this.state.activeRes)) -
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrResUTD
                              ).toFixed(2)
                            ) /
                              Number(
                                this.occNights(this.state.activePrevYrResUTD)
                              )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : this.netRevenue(this.state.activeRes) !== 0
                        ? (
                            Number(
                              this.netRevenue(this.state.activeRes).toFixed(2)
                            ) / Number(this.occNights(this.state.activeRes))
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "-" +
                          (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrResUTD
                              ).toFixed(2)
                            ) /
                            Number(
                              this.occNights(this.state.activePrevYrResUTD)
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-9"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {this.state.activeRes.length}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Reservations</b>
                  </Card.Text>
                  <hr />
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:</b>{" "}
                      {this.state.activePrevYrResUTD.length
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      <br />
                      <b>Gap:{"  "}</b>
                      {(
                        this.state.activeRes.length -
                        this.state.activePrevYrResUTD.length
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-9"
                style={{
                  width: "9rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {this.aveLOS(this.state.activeRes).toFixed(1)}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Average LOS</b>
                  </Card.Text>
                  <hr />
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b>
                      {!this.aveLOS(this.state.activePrevYrResUTD) 
                        ? "$0"
                        : this.aveLOS(this.state.activePrevYrResUTD).toFixed(1)}
                      <br />
                      <b>Gap:{"  "}</b>

                      {!this.aveLOS(this.state.activePrevYrResUTD) && !this.aveLOS(this.state.activePrevYrResUTD)
                        ? "n/a"
                        : (
                            Number(
                              this.aveLOS(this.state.activeRes).toFixed(1)
                            ) -
                            Number(
                              this.aveLOS(
                                this.state.activePrevYrResUTD
                              ).toFixed(1)
                            )
                          )
                            .toFixed(1)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-9"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    $
                    {(
                      Number(this.netRevenue(this.state.activeRes)) /
                      this.state.activeRes.length
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net ATV</b>
                  </Card.Text>
                  <hr />
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:</b>{"  "}$
                      {!this.netRevenue(this.state.activePrevYrResUTD) ? "0": Number(
                        this.netRevenue(this.state.activePrevYrResUTD) /
                          this.state.activePrevYrResUTD.length
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      <br />
                      <b>Gap:{"  "}</b>$
                      {this.state.activeRes.length <= 0 || this.state.activePrevYrResUTD.length <= 0 ? "n/a":(
                        Number(
                          this.netRevenue(this.state.activeRes) /
                            this.state.activeRes.length
                        ) -
                        Number(
                          this.netRevenue(this.state.activePrevYrResUTD) /
                            this.state.activePrevYrResUTD.length
                        )
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
            </Row>
            <hr />
            {/* <Row style={{ marginTop: "4rem" }}>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Net Revenue
                </h5>
                <Bar data={netRev} width={15} height={15} options={options2} />
              </Col>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Booked Nights
                </h5>
                <Bar
                  data={bookedNights}
                  width={15}
                  height={15}
                  options={options2}
                />
              </Col>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Net ADR
                </h5>
                <Bar data={netADR} width={15} height={15} options={options2} />
              </Col>
            </Row>
            <Row style={{ marginTop: "4rem", marginBottom: "6rem" }}>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Reservations
                </h5>
                <Bar
                  data={reservations}
                  width={15}
                  height={15}
                  options={options2}
                />
              </Col>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Average LOS
                </h5>
                <Bar data={aveLOS} width={15} height={15} options={options2} />
              </Col>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Net ATV
                </h5>
                <Bar data={netATV} width={15} height={15} options={options2} />
              </Col>
            </Row> */}
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default ListingPerformance;
