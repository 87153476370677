import React, { Component } from 'react';
import MetaTags from "react-meta-tags";
import { Route, Switch } from "react-router-dom";
import actions from "./services/index";
import "bootstrap/dist/css/bootstrap.css";
import './App.css';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Login from './components/Login';
import Main from './components/Main';
import Units from "./components/Units";
import Calendar from "./components/Calendar";
import NotFound from './components/NotFound';
import Events from './components/Events';
import Event from "./components/Event";
import EditUnit from './components/EditUnit';
import Reservations from './components/Reservations';
import Hub from './components/Hub';
import ListingResearch from "./components/ListingResearch";
import Home from './components/Home';
import "bootstrap/dist/js/bootstrap.bundle.min";
import SearchListing from './components/SearchListing';
import ListingOverview from './components/ListingOverview';
import ListingPerformance from './components/ListingPerformance';
import Clients from './components/Clients';
import ClientListings from './components/ClientListings';
import ClientHub from './components/ClientHub';
import PricingPull from './components/PricingPull';
import Demand from './components/Demand';
import Forecast from './components/Forecast';
import Rules from './components/Rules';
import MyMarkets from './components/MyMarkets';



class App extends Component {
  state = {
    users: [],
  };

  async componentDidMount() {
    let user = await actions.isLoggedIn();
    this.setState({ ...user.data });
    // console.log("Current User >> ", user);
  }

  setUser = (user) => this.setState(user);

  render() {
    return (
      <div className="App">
        <MetaTags>
          <title>QuibbleRM - Pricing & Revenue Management</title>
          <meta property="og:url" content="https://quibble.dev" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="QuibbleRM" />
          <meta
            property="og:description"
            content="QuibbleRM - Revenue Management Software for Vacation Rentals"
          />
          <meta
            property="og:image"
            content="../src/img/FullColor_1024x1024_300dpi.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="https://quibble.dev" />
          <meta name="twitter:creator" content="Quibble" />
          <meta name="twitter:title" content="QuibbleRM" />
          <meta
            name="twitter:description"
            content="QuibbleRM - Revenue Management Software for Vacation Rentals"
          />
          <meta
            name="twitter:image"
            // content="../src/img/FullColor_1024x1024_300dpi.jpg"
          />
        </MetaTags>
        <Navigation setUser={this.setUser} user={this.state} />

        <Switch>
          <Route exact path="/" render={(props) => <Home {...props} />} />
          <Route
            exact
            path="/login"
            render={(props) => <Login {...props} setUser={this.setUser} />}
          />
          <Route exact path="/home" render={(props) => <Main {...props} />} />
          <Route exact path="/units" render={(props) => <Units {...props} />} />
          <Route exact path="/demand/:id" render={(props) => <Demand {...props} />} />
          <Route exact path="/forecast" render={(props) => <Forecast {...props} />} />      
          <Route exact path="/rules" render={(props) => <Rules {...props} />} />
          <Route exact path="/mymarkets" render={(props) => <MyMarkets {...props} />} />
          <Route
            exact
            path="/unit/:id"
            render={(props) => <EditUnit {...props} />}
          />
          <Route
            exact
            path="/listing/:id"
            render={(props) => <ListingOverview {...props} />}
          />
          <Route
            exact
            path="/events/:id"
            render={(props) => <Events {...props} />}
          />
          <Route
            exact
            path="/event/:id"
            render={(props) => <Event {...props} />}
          />
          <Route
            exact
            path="/reservations/:id"
            render={(props) => <Reservations {...props} />}
          />
          <Route
            exact
            path="/market"
            render={(props) => <SearchListing {...props} />}
          />
          <Route
            exact
            path="/markets/:id"
            render={(props) => <ListingResearch {...props} />}
          />
          <Route exact path="/hub" render={(props) => <Hub {...props} />} />
          <Route
            exact
            path="/calendar/:id"
            render={(props) => <Calendar {...props} />}
          />
          <Route
            exact
            path="/performance/:id"
            render={(props) => <ListingPerformance {...props} />}
          />
          <Route
            exact
            path="/qRMaccess/clients"
            render={(props) => <Clients {...props} />}
          />
          <Route
            exact
            path="/qRMaccess/clientListings/:id"
            render={(props) => <ClientListings {...props} />}
          />
          <Route
            exact
            path="/qRMaccess/$crapingQ"
            render={(props) => <PricingPull {...props} />}
          />
          <Route
            exact
            path="/qRMaccess/hub/:id"
            render={(props) => <ClientHub {...props} />}
          />

          <Route component={NotFound} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;
