import React, { Component } from "react";
import { Container, Row, Col, Card, Button, InputGroup, FormControl, Table, Form } from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";
import "bootstrap/js/src/collapse.js";
// import { Bar } from "react-chartjs-2";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL =
      "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

class Demand extends Component {
  state = {
    reservations: [],
    showModalEvent: false,
    activeRes: [],
    activeMonthlyRes: [],
    activePrevYrMonthlyResUTD: [],
    activePrevYearlyRes: [],
    activePrevYrMonthlyRes: [],
    activePrevYrResUTD: [],
    compData: "<< Hello World! >>",
    events: [],
    show: false,
    oneDay: 1000 * 60 * 60 * 24,
    model: [],
    listingData: [],
    unitData: [],
  };

  componentDidMount() {
    let user = actions.isLoggedIn();
    this.setState({ ...user.data });

    axios
      .get(
        `${baseURL}/api/reservation/activeYearlyRes/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activeRes: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/reservation/activePrevYearlyRes/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activePrevYrRes: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/reservation/activePrevYearlyResUTD/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activePrevYrResUTD: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/reservation/activeMonthlyRes/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activeMonthlyRes: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/reservation/activePrevYrMonthlyResUTD/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activePrevYrMonthlyResUTD: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/reservation/activePrevYrMonthlyRes/${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ activePrevYrMonthlyRes: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/pulse/last/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ events: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/market/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ listingData: res.data[0] });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/eqrms/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ model: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/unit/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ unitData: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  showAlert = () => {
    this.setState({ show: true });
  };

  hideAlert = (e) => {
    this.setState({ show: false });
    // console.log(e, this.state)
  };

  switchADR = () => {
    let adr;
    if (
      this.state.listingData.price === "" ||
      this.state.events[0].price === null
    ) {
      adr = this.state.events[0].price;
      return adr;
    } else {
      return this.state.listingData.price;
    }
  };

  pulseADR = () => {
    let total = 0;
    let average;
    for (let i = 0; i < this.state.events.length; i++) {
      if (this.state.events[i].price !== null) {
        total += Number(this.state.events[i].price.slice(1).replace(",", ""));
      } else {
        total = null;
      }
    }
    if (total !== null) {
      average = total / this.state.events.length;
    } else {
      average = 0;
    }
    return average;
  };

  renderDay = (date) => {
    let a = new Date(date);
    let days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    let dayOfWeek = days[a.getDay()];
    return dayOfWeek;
  };

  mthdayOcc = (days) => {
    let occ = 0;
    for (let i = 0; i < 1; i++) {
      let counter = 0;
      this.state.events.slice(0, days).forEach((event) => {
        if (event.available !== true) {
          counter++;
        }
      });
      // console.log(counter)
      occ = ((counter / days) * 100).toFixed(0);
    }
    return occ;
  };

  getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  occNights = (arr) => {
    let totalNights = 0;
    // let arr = this.state.activeRes;
    //   for (let i = 0; i < arr.length; i++) {
    let counter = 0;
    arr.forEach((booking) => {
      if (
        booking.user_facing_status_localized === "Confirmed" ||
        booking.user_facing_status_localized === "reserved"
      ) {
        counter += Number(booking.nights);
        // console.log(counter)
      }
    });
    totalNights = counter;
    return totalNights;
  };

  netRevenue = (arr) => {
    let totalRevenue = 0;
    // let arr = this.state.activeRes;
    //   for (let i = 0; i < arr.length; i++) {
    let counter = 0;
    arr.forEach((booking) => {
      if (
        booking.user_facing_status_localized === "Confirmed" ||
        booking.user_facing_status_localized === "reserved"
      ) {
        counter += Number(booking.earnings.replace(/[,|$]/g, ""));
        // console.log(counter);
      }
    });
    totalRevenue = counter;
    return totalRevenue;
  };

  aveLOS = (arr) => {
    let averageLOS = 0;
    let bookings = 0;
    // let arr = this.state.activeRes;
    //   for (let i = 0; i < arr.length; i++) {
    let counter = 0;
    arr.forEach((booking) => {
      if (
        booking.user_facing_status_localized === "Confirmed" ||
        booking.user_facing_status_localized === "reserved"
      ) {
        counter += booking.nights;
        bookings++;
        // console.log(counter);
      }
    });
    averageLOS = counter / bookings;
    // console.log(bookings, counter)
    return averageLOS;
  };

  handleCardClick = (e) => {
    console.log(e.target.id);
  };

  render() {
    console.log(this.state);
    const authToken = localStorage.getItem(AUTH_TOKEN);
    if (authToken) {
      return (
        <React.Fragment>
          <Container
            style={{ marginTop: "2em", height: "100%", overflowY: "scroll" }}
          >
            <Row
              style={{
                marginBottom: "2em",
                marginLeft: "1.5rem",
                marginTop: "2rem",
              }}
            >
              <Col xs={8}>
                <h1>
                  Demand Manager{" "}
                  <Button
                    as={Link}
                    to={"/listing/" + this.props.match.params.id}
                    variant="outline-dark ml-4"
                  >
                    Overview
                  </Button>
                  <Button
                    as={Link}
                    to={"/calendar/" + this.props.match.params.id}
                    variant="outline-warning ml-2"
                  >
                    🗓
                  </Button>
                  {/* <Button
                    as={Link}
                    to={"/events/" + this.props.match.params.id}
                    variant="outline-info ml-2"
                  >
                    Pricing List
                  </Button> */}
                  <Button
                    as={Link}
                    to={"/reservations/" + this.props.match.params.id}
                    variant="primary ml-2"
                  >
                    Reservations
                  </Button>
                </h1>
              </Col>
              <Col lg={4}>
                <Form inline style={{ marginTop: "0.5rem" }}>
                  <Form.Label>
                    Select Month
                    <Form.Control style={{ marginLeft: "1rem" }} as="select">
                      <option selected>Current Month</option>
                      <option>January 2021</option>
                      <option>February 2021</option>
                      <option>March 2021</option>
                      <option>April 2021</option>
                      <option>May 2021</option>
                      <option>June 2021</option>
                      <option>July 2021</option>
                      <option>August 2021</option>
                      <option>September 2021</option>
                      <option>October 2021</option>
                      <option>November 2021</option>
                      <option>December 2021</option>
                    </Form.Control>
                  </Form.Label>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col lg={12} style={{ textAlign: "center" }}>
                <h5 style={{ fontWeight: "bolder" }}>
                  - Month to Date Summary -
                </h5>
              </Col>
            </Row>
            <Row
              style={{ marginBottom: "3em", overflowX: "scroll" }}
              className="center2"
            >
              <div
                id="monthlyRev"
                name="monthlyRev"
                onClick={(e) => this.handleCardClick(e)}
              >
                <Card
                  className="bg-custom-4"
                  text="dark"
                  variant="info"
                  style={{
                    width: "9.5rem",
                    marginTop: "2rem",
                    marginLeft: "2rem",
                  }}
                >
                  {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                  <Card.Body>
                    <Card.Title style={{ textAlign: "center" }}>
                      $
                      {this.netRevenue(this.state.activeMonthlyRes)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Card.Title>
                    <Card.Text
                      style={{ textAlign: "center", fontSize: "0.9em" }}
                    >
                      <b>Net Revenue</b>
                    </Card.Text>

                    <div
                      style={{
                        backgroundColor: "whitesmoke",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.001rem",
                        borderRadius: "10px",
                        paddingLeft: "0.3rem",
                        paddingRight: "0.3rem",
                      }}
                    >
                      <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                        <b>Benchmarks</b>
                      </h6>
                      <blockquote
                        style={{ fontSize: "0.5em", textAlign: "center" }}
                      >
                        <b>Last Yr UTD:{"  "}</b> $
                        {this.netRevenue(this.state.activePrevYrMonthlyResUTD)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        <br />
                        <b>Gap:{"  "}</b> $
                        {(
                          this.netRevenue(this.state.activeMonthlyRes) -
                          this.netRevenue(this.state.activePrevYrMonthlyResUTD)
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        <hr />
                        <h6 style={{ fontSize: "1em" }}>
                          <i>Full Month Data</i>
                        </h6>
                        <b>Last Year:{"  "}</b> $
                        {this.netRevenue(this.state.activePrevYrMonthlyRes)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        <br />
                        <b>Y-o-Y:{"  "}</b>$
                        {(
                          this.netRevenue(this.state.activeMonthlyRes) -
                          this.netRevenue(this.state.activePrevYrMonthlyRes)
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </blockquote>
                      {/* <Button name="monthlyRev" id="monthlyRev" onClick={(e)=>this.handleCardClick(e)} block variant="primary">Analytics</Button> */}
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {this.occNights(this.state.activeMonthlyRes)}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Booked Nights</b>
                  </Card.Text>
                  {/* <hr /> */}
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                      paddingLeft: "0.3rem",
                      paddingRight: "0.3rem",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b>
                      {this.occNights(this.state.activePrevYrMonthlyResUTD)}
                      <br />
                      <b>Gap:{"  "}</b>{" "}
                      {this.occNights(this.state.activeMonthlyRes) -
                        this.occNights(this.state.activePrevYrMonthlyResUTD) >
                      0
                        ? "+"
                        : ""}
                      {this.occNights(this.state.activeMonthlyRes) -
                        this.occNights(this.state.activePrevYrMonthlyResUTD)}
                      <hr />
                      <h6 style={{ fontSize: "1em" }}>
                        <i>Full Month Data</i>
                      </h6>
                      <b>Last Year:{"  "}</b>
                      {this.occNights(this.state.activePrevYrMonthlyRes)}
                      <br />
                      <b>Y-o-Y:{"  "}</b>
                      {this.occNights(this.state.activeMonthlyRes) -
                        this.occNights(this.state.activePrevYrMonthlyRes) >
                      0
                        ? "+"
                        : ""}
                      {this.occNights(this.state.activeMonthlyRes) -
                        this.occNights(this.state.activePrevYrMonthlyRes)}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    $
                    {this.netRevenue(this.state.activeMonthlyRes) !== 0
                      ? (
                          Number(
                            this.netRevenue(
                              this.state.activeMonthlyRes
                            ).toFixed(2)
                          ) /
                          Number(this.occNights(this.state.activeMonthlyRes))
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "0.00"}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net ADR</b>
                  </Card.Text>
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                      paddingLeft: "0.3rem",
                      paddingRight: "0.3rem",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b> $
                      {this.occNights(this.state.activePrevYrMonthlyResUTD) !==
                      0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyResUTD
                              ).toFixed(2)
                            ) /
                            Number(
                              this.occNights(
                                this.state.activePrevYrMonthlyResUTD
                              )
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "0"}
                      <br />
                      <b>Gap:{"  "}</b> $
                      {this.netRevenue(this.state.activePrevYrMonthlyResUTD) !==
                      0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activeMonthlyRes
                              ).toFixed(2)
                            ) /
                              Number(
                                this.occNights(this.state.activeMonthlyRes)
                              ) -
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyResUTD
                              ).toFixed(2)
                            ) /
                              Number(
                                this.occNights(
                                  this.state.activePrevYrMonthlyResUTD
                                )
                              )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : this.netRevenue(this.state.activeMonthlyRes) !== 0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activeMonthlyRes
                              ).toFixed(2)
                            ) /
                            Number(this.occNights(this.state.activeMonthlyRes))
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "-" +
                          (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyResUTD
                              ).toFixed(2)
                            ) /
                            Number(
                              this.occNights(
                                this.state.activePrevYrMonthlyResUTD
                              )
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      <hr />
                      <h6 style={{ fontSize: "1em" }}>
                        <i>Full Month Data</i>
                      </h6>
                      <b>Last Year:</b> $
                      {this.occNights(this.state.activePrevYrMonthlyRes) !== 0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyRes
                              ).toFixed(2)
                            ) /
                            Number(
                              this.occNights(this.state.activePrevYrMonthlyRes)
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "0"}
                      <br />
                      <b>Y-o-Y:{"  "}</b>$
                      {this.netRevenue(this.state.activeMonthlyRes) !== 0 &&
                      this.netRevenue(this.state.activePrevYrMonthlyRes) !== 0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activeMonthlyRes
                              ).toFixed(2)
                            ) /
                              Number(
                                this.occNights(this.state.activeMonthlyRes)
                              ) -
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyRes
                              ).toFixed(2)
                            ) /
                              Number(
                                this.occNights(
                                  this.state.activePrevYrMonthlyRes
                                )
                              )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : this.netRevenue(this.state.activeMonthlyRes) !== 0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activeMonthlyRes
                              ).toFixed(2)
                            ) /
                            Number(this.occNights(this.state.activeMonthlyRes))
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "-" +
                          (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyRes
                              ).toFixed(2)
                            ) /
                            Number(
                              this.occNights(this.state.activePrevYrMonthlyRes)
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {this.state.activeMonthlyRes.length}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Reservations</b>
                  </Card.Text>
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                      paddingLeft: "0.3rem",
                      paddingRight: "0.3rem",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b>
                      {this.state.activePrevYrMonthlyResUTD.length}
                      <br />
                      <b>Gap:{"  "}</b>

                      {this.state.activeMonthlyRes.length -
                        this.state.activePrevYrMonthlyResUTD.length}
                      <hr />
                      <h6 style={{ fontSize: "1em" }}>
                        <i>Full Month Data</i>
                      </h6>
                      <b>Last Year:{"  "}</b>
                      {this.state.activePrevYrMonthlyRes.length}
                      <br />
                      <b>Y-o-Y:{"  "}</b>

                      {this.state.activeMonthlyRes.length -
                        this.state.activePrevYrMonthlyRes.length}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {this.aveLOS(this.state.activeMonthlyRes).toFixed(1)}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Average LOS</b>
                  </Card.Text>
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                      paddingLeft: "0.3rem",
                      paddingRight: "0.3rem",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b>
                      {!this.aveLOS(this.state.activePrevYrMonthlyResUTD)
                        ? "0"
                        : this.aveLOS(
                            this.state.activePrevYrMonthlyResUTD
                          ).toFixed(1)}
                      <br />
                      <b>Gap:{"  "}</b>

                      {!this.aveLOS(this.state.activePrevYrMonthlyResUTD) ||
                      !this.aveLOS(this.state.activeRes)
                        ? "n/a"
                        : (
                            Number(
                              this.aveLOS(this.state.activeMonthlyRes).toFixed(
                                1
                              )
                            ) -
                            Number(
                              this.aveLOS(
                                this.state.activePrevYrMonthlyResUTD
                              ).toFixed(1)
                            )
                          )
                            .toFixed(1)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      <hr />
                      <h6 style={{ fontSize: "1em" }}>
                        <i>Full Month Data</i>
                      </h6>
                      <b>Last Year:{"  "}</b>
                      {!this.aveLOS(this.state.activePrevYrMonthlyRes) ||
                      !this.aveLOS(this.state.activeRes)
                        ? "0"
                        : this.aveLOS(
                            this.state.activePrevYrMonthlyRes
                          ).toFixed(1)}
                      <br />
                      <b>Y-o-Y:{"  "}</b>

                      {!this.aveLOS(this.state.activePrevYrMonthlyRes) ||
                      !this.aveLOS(this.state.activeRes)
                        ? "n/a"
                        : (
                            Number(
                              this.aveLOS(this.state.activeMonthlyRes).toFixed(
                                1
                              )
                            ) -
                            Number(
                              this.aveLOS(
                                this.state.activePrevYrMonthlyRes
                              ).toFixed(1)
                            )
                          )
                            .toFixed(1)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    $
                    {(
                      Number(this.netRevenue(this.state.activeMonthlyRes)) /
                      this.state.activeMonthlyRes.length
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net ATV</b>
                  </Card.Text>
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.001rem",
                      borderRadius: "10px",
                      paddingLeft: "0.3rem",
                      paddingRight: "0.3rem",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "0.7em" }}>
                      <b>Benchmarks</b>
                    </h6>
                    <blockquote
                      style={{ fontSize: "0.5em", textAlign: "center" }}
                    >
                      <b>Last Yr UTD:{"  "}</b> $
                      {this.state.activePrevYrMonthlyResUTD.length > 0
                        ? (
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyResUTD
                              )
                            ) / this.state.activePrevYrMonthlyResUTD.length
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "0"}
                      <br />
                      <b>Gap:{"  "}</b>
                      {this.state.activePrevYrMonthlyResUTD.length === 0
                        ? "n/a"
                        : "$" +
                          (
                            Number(
                              this.netRevenue(this.state.activeMonthlyRes) /
                                this.state.activeMonthlyRes.length
                            ) -
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyResUTD
                              ) / this.state.activePrevYrMonthlyResUTD.length
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      <hr />
                      <h6 style={{ fontSize: "1em" }}>
                        <i>Full Month Data</i>
                      </h6>
                      <b>Last Year:{"  "}</b>$
                      {this.state.activePrevYrMonthlyRes.length < 0
                        ? "$" +
                          (
                            Number(
                              this.netRevenue(this.state.activePrevYrMonthlyRes)
                            ) / this.state.activePrevYrMonthlyRes.length
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "0"}{" "}
                      <br />
                      <b>Y-o-Y:{"  "}</b>
                      {this.state.activePrevYrMonthlyRes.length === 0
                        ? "n/a"
                        : "$" +
                          (
                            Number(
                              this.netRevenue(this.state.activeMonthlyRes) /
                                this.state.activeMonthlyRes.length
                            ) -
                            Number(
                              this.netRevenue(
                                this.state.activePrevYrMonthlyRes
                              ) / this.state.activePrevYrMonthlyRes.length
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </blockquote>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </div>
                </Card.Body>
              </Card>
            </Row>
            {this.state.events.length > 0 ? (
              <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                <thead>
                  <tr>
                    <th>Occ</th>
                    <th>Location</th>
                    <th>Season</th>
                    <th>Date</th>
                    <th>DoW</th>
                    <th>Lead</th>
                    {/* <th>Occ Signal</th> */}
                    {/* <th>EMRRq $</th> */}
                    {/* <th>Current Price</th> */}
                    {/* {this.state.unitData.length > 0 ? (
                      <th>Override Price</th>
                    ) : (
                      <></>
                    )} */}
                    <th>Demand</th>
                    <th>Remaining</th>
                    <th>Mkt OCC</th>
                    <th>1 day</th>
                    <th>3 day</th>
                    <th>7 day</th>
                    <th>21 day</th>
                    <th>Price</th>
                    <th>Price_GA</th>
                    <th>MinNight</th>
                    {/* <th>
                      View {this.state.unitData.length > 0 ? "/ Actions" : ""}
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.events.slice(0, 30).map((event, i) => {
                    return (
                      <tr key={i}>
                        <td>{event.available ? " 🟢 " : " 🔴  "}</td>
                        <td>City, ST</td>
                        <td>High</td>
                        <td>{event.calendarDate}</td>
                        <td>{this.renderDay(event.calendarDate)}</td>
                        <td>
                          {(
                            (new Date(event.calendarDate) - new Date()) /
                              this.state.oneDay +
                            1
                          ).toFixed(0)}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        {this.state.unitData.length > 0 ? (
                          <td>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder={
                                  event.available ? event.price : "-"
                                }
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                disabled={!event.available}
                              />
                            </InputGroup>
                          </td>
                        ) : (
                          <></>
                        )}
                        <td className={event.available ? "" : "blurry-text"}>
                          {event.price !== null && event.available
                            ? event.price
                            : "$**.**"}
                        </td>
                        <td>{event.minNights}</td>
                        {/* <td>
                          <Button
                            // as={Link}
                            // to={`/event/${event._id}`}
                            onClick={(e) => this.showAlert(e)}
                            variant="outline-dark"
                            size="sm"
                            // disabled={
                            //   event.calendarDate >=
                            //   String(new Date().toISOString()).slice(0, 10)
                            //     ? !event.available
                            //     : true
                            // }
                          >
                            Insights 💡
                          </Button>
                          {this.state.unitData.length > 0 ? (
                            <Button
                              // as={Link}
                              // to={`/event/${event._id}`}
                              variant="outline-success"
                              size="sm ml-2"
                              disabled={
                                event.calendarDate >=
                                String(new Date().toISOString()).slice(0, 10)
                                  ? !event.available
                                  : true
                              }
                            >
                              Sync 🔄
                            </Button>
                          ) : (
                            <></>
                          )}
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <h4 className="loading">Loading Events for Listing...</h4>
            )}
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Demand;
