//General Setup Declared Variables
let events = [];
let calendar = [];
let pricing = [];

let minNS = 2;


// Comps Variables for Pricing Model
// let varianceJanADR = 0;
// let varianceFebADR = 0;
// let varianceMarADR = 0;
// let varianceAprADR = 0;
// let varianceMayADR = 0;
// let varianceJunADR = 0;
// let varianceJulADR = 0;
// let varianceAugADR = 0;
// let varianceSepADR = 0;
// let varianceOctADR = 0;
// let varianceNovADR = 0;
// let varianceDicADR = 0;



// Variables Setup Month
let janVar = 1.1
let febVar = 1;
let marVar = 1.3;
let aprVar = 1;
let mayVar = 0.9;
let junVar = 1.4;
let julVar = 1.3;
let augVar = 0.85;
let sepVar = 0.9;
let octVar = 1;
let novVar = 1.2;
let decVar = 1.4;

// Variables Setup Day of Week
let monVar = 0.8;
let tueVar = 0.9;
let wedVar = 0.95;
let thuVar = 1;
let friVar = 1.2;
let satVar = 1.3;
let sunVar = 1.1;

// Variable Setup Week of the Year
// let week0 = 1.4;
// let week1 = 1.2;
// let week2 = 1.18;
// let week3 = 1.39;
// let week4 = 1.134;
// let week5 = 1.2;
// let week6 = 0.8;
// let week7 = 0.9;
// let week8 = 1.10;
// let week9 = 1.2;
// let week10 = 0.8;
// let week11 = 0.9;
// let week12 = 1.10;
// let week13 = 1.2;
// let week14 = 0.8;
// let week15 = 0.9;
// let week16 = 1.10;
// let week17 = 1.2;
// let week18 = 0.8;
// let week19 = 0.9;
// let week20 = 1.10;
// let week21 = 1.2;
// let week22 = 0.8;
// let week23 = 0.9;
// let week24 = 1.10;
// let week25 = 1.2;
// let week26 = 0.8;
// let week27 = 0.9;
// let week28 = 1.10;
// let week29 = 1.2;
// let week30 = 0.8;
// let week31 = 0.9;
// let week32 = 1.10;
// let week33 = 1.2;
// let week34 = 1.23;
// let week35 = 1.24;
// let week36 = 1.28;
// let week37 = 1.10;
// let week38 = 1.05;
// let week39 = 1.20;
// let week40 = 0.8;
// let week41 = 0.75;
// let week42 = 0.87;
// let week43 = 0.9;
// let week44 = 1.120;
// let week45 = 1.120;
// let week46 = 1.120;
// let week47 = 1.120;
// let week48 = 1.120;
// let week49 = 1.120;
// let week50 = 1.30;
// let week51 = 1.330;
// let week52 = 1.34560;
// let week53 = 1.36;
// let week54 = 1.36
// let week55 = 1.36
// let week56 = 1.36
// let week57 = 1.36
// let week58 = 1.36
// let week59 = 1.36




function buildCalendar(basePrice) {
  console.log(basePrice)
  for (let i = 0; i < 365; i++) {
    let price = basePrice;
    let today = new Date();
    let buildDate = new Date(today);
    buildDate.setDate(buildDate.getDate() + i);
    let date = buildDate.toISOString().slice(0, 10);

    // Day of Week Adjustment by percentage change - (to be switch to model variable)
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 0) {
      price = Number((basePrice * sunVar * janVar).toFixed(0));
    }
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 1) {
      price = Number((basePrice * sunVar * febVar).toFixed(0));
    }
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 2) {
      price = Number((basePrice * sunVar * marVar).toFixed(0));
    }
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 3) {
      price = Number((basePrice * sunVar * aprVar).toFixed(0));
    }
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 4) {
      price = Number((basePrice * sunVar * mayVar).toFixed(0));
    }
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 5 ) {
      price = Number((basePrice * sunVar * junVar).toFixed(0));
    }
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 6) {
      price = Number((basePrice * sunVar * julVar).toFixed(0));
    }
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 7) {
      price = Number((basePrice * sunVar * augVar).toFixed(0));
    }
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 8) {
      price = Number((basePrice * sunVar * sepVar).toFixed(0));
    }
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 9) {
      price = Number((basePrice * sunVar * octVar).toFixed(0));
    }
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 10) {
      price = Number((basePrice * sunVar * novVar).toFixed(0));
    }
    if (buildDate.getDay() === 0 && buildDate.getMonth() === 11) {
      price = Number((basePrice * sunVar * decVar).toFixed(0));
    }
    if (buildDate.getDay() === 1) {
      price = Number((basePrice * monVar).toFixed(0));
    }
    if (buildDate.getDay() === 2) {
      price = Number((basePrice * tueVar).toFixed(0));
    }
    if (buildDate.getDay() === 3) {
      price = Number((basePrice * wedVar).toFixed(0));
    }
    if (buildDate.getDay() === 4) {
      price = Number((basePrice * thuVar).toFixed(0));
    }
    if (buildDate.getDay() === 5) {
      price = Number((basePrice * friVar).toFixed(0));
    }
    if (buildDate.getDay() === 6) {
      price = Number((basePrice * satVar).toFixed(0));
    }
    events.push({
      start: date + ":00:00:00",
      end: date + ":00:00:00",
      title: String(price + " -  🌜" + minNS),
    });
    calendar.push({
      startDate: date,
      endDate: date,
      price: price,
      minNS: minNS,
    });
    pricing.push({
      calendarDate: date,
      price: price,
      minNS: minNS,
    });
  }
  // console.log(calendar)
  // Pushing this filter function to the Calendar.jsx to filter for the frontend but still have the full 365 days available to push to PMS
  // function comparer(currEvents) {
  //   return function (events) {
  //     return (
  //       currEvents.filter(function (current) {
  //         return (
  //           current.start === events.start && current.available === true
  //         )}).length === 1
  //     );
  //   };
  // }
  
  // let updatedPriceCalendar = events.filter(comparer(currEvents));
  return {events,calendar,pricing};
};

// console.log(buildCalendar());

module.exports = buildCalendar;