import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap';
import logo from "../img/quibble-logo.png";
import icon from "../img/quibble_favicon.png"
import bag from "../img/quibbleBag.png"


class Footer extends Component {
    render() {
        return (
          <React.Fragment>
            <footer>
              <div
                className="footer"
                style={{
                  backgroundColor: "#242726",
                  paddingTop: "40px",
                  paddingBottom: "80px",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                <Row
                  style={{
                    display: "align-space-evenly",
                    marginRight: "20px",
                    marginLeft: "20px",
                  }}
                >
                  <Col lg={{ span: 5, offset: 1 }} md={12} sm={12}>
                    <img
                      alt="Quibble"
                      src={logo}
                      width="250"
                      className="d-inline-block align-top"
                    />
                    <p>
                      We created Quibble to address the gap in the market
                      between the simple rules-based software applications
                      currently available and a truly science-based
                      optimization. Quibble bundles our software with a full
                      service offering to give our clients complete Pricing Ease
                      of Mind.
                    </p>
                    <p>
                      {" "}
                      With many years of revenue management experience in the
                      travel and hospitality industries, we know the pains and
                      uncertainty revenue management brings. At Quibble, we aim
                      to increase your revenue performance by engineering and
                      implementing your custom pricing strategy while developing
                      long-lasting partnerships.
                    </p>
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    className="logoCenter text-center"
                  >
                    <a
                      href="https://quibblerm.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={bag}
                        alt="QuibbleRM"
                        width="250"
                        height="250"
                        align="center"
                      />
                    </a>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  backgroundColor: "#221f1f",
                  color: "white",
                  textDecoration: "none",
                }}
                className="footer footer-copyright text-center py-3"
              >
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                  {" "}
                  <img alt="Quibble" src={icon} width="20" height="20" />{" "}
                  QuibbleRM by Quibble{" "}
                </Link>{" "}
                <br /> All Rights Reserved © {new Date().getFullYear()}{" "}
              </div>
            </footer>
          </React.Fragment>
        );
    }
}

export default Footer;