import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Col, Row } from "react-bootstrap";

class Forecast extends Component {
  render() {
    return (
      <React.Fragment>
        <Container style={{ height: "100vh", marginTop: "6rem" }}>
          <Row>
            <Col>
              <center>
                <h1>
                  <b>Forecast Manager</b>
                </h1>
              </center>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Forecast;
