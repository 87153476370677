import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL = "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

class Event extends Component {
  state = {
    event: [],
  };

  async componentDidMount() {
    let user = await actions.isLoggedIn();
    this.setState({ ...user.data });

    await axios
      .get(`${baseURL}/api/event/editEvent/${this.props.match.params.id}`)
      // .get(`http://localhost:4000/api/event/editEvent/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ event: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let newEvent = {
      date: this.state.date,
      minNS: this.state.minNS,
      qrmPrice: this.state.qrmPrice,
      basePrice: this.state.basePrice,
      minPrice: this.state.minPrice,
      maxPrice: this.state.maxPrice,
    };
    alert("Changes submitted!");
    // this.props.history.push("/units");
    await axios
      .post(
        // `https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com/api/event/editEvent/${this.props.match.params.id}`,
        `http://localhost:4000/api/event/editEvent/${this.props.match.params.id}`,
        newEvent
      )
      .then((res) => {
        console.log(res.data);
      });
  };

  render() {
      const authToken = localStorage.getItem(AUTH_TOKEN);
    if (authToken) {
      console.log(this.state);
      return (
        <React.Fragment>
          <Container style={{ marginTop: "2em", marginBottom: "2em" }}>
            <Row>
              <Col xs={12}>
                <h1>Edit Event</h1>
              </Col>
            </Row>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      name="date"
                      type="date"
                      required
                      disabled
                    //   onChange={this.handleChange}
                      defaultValue={this.state.event.date !== undefined ? this.state.event.date.slice(0,10) : null}
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Label>Minimum Night Stays</Form.Label>
                    <Form.Control
                      name="minNS"
                      onChange={this.handleChange}
                      defaultValue={this.state.event.minNS}
                    ></Form.Control>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={3} lg={3}>
                    <Form.Label>QuibbleRM Price</Form.Label>
                    <Form.Control
                      name="qrmPrice"
                      onChange={this.handleChange}
                      defaultValue={this.state.event.qrmPrice}
                    ></Form.Control>
                  </Col>
                  <Col xs={3} lg={3}>
                    <Form.Label>Base Price</Form.Label>
                    <Form.Control
                      name="basePrice"
                      onChange={this.handleChange}
                      defaultValue={this.state.event.basePrice}
                    ></Form.Control>
                  </Col>
                  <Col xs={3} lg={3}>
                    <Form.Label>Min Price</Form.Label>
                    <Form.Control
                      name="minPrice"
                      onChange={this.handleChange}
                      defaultValue={this.state.event.minPrice}
                    ></Form.Control>
                  </Col>
                  <Col xs={3} lg={3}>
                    <Form.Label>Max Price</Form.Label>
                    <Form.Control
                      name="maxPrice"
                      onChange={this.handleChange}
                      defaultValue={this.state.event.maxPrice}
                    ></Form.Control>
                  </Col>
                </Row>
              </FormGroup>
              <Row>
                <Col>
                  <Form.Control
                    type="submit"
                    value="Submit"
                    className="btn btn-success"
                  />
                </Col>
              </Row>
            </Form>
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Event;