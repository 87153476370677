import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL = "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

class Main extends Component {
  state = {
    reservations: [],
    showModalEvent: false,
  };

  async componentDidMount() {
    let user = await actions.isLoggedIn();
    this.setState({ ...user.data });

    await axios
      .get(`${baseURL}/api/reservation/`)
      // .get(`http://localhost:4000/api/reservation/`)
      .then((res) => {
        this.setState({ reservations: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  groupData = (array) => {
    var r = [];
    array.forEach((a) => {
      if (!this[a.booked_date]) {
        let bd = new Date(a.booked_date)
        let bdyr = bd.getFullYear();
        this[a.booked_date] = {
          booked_year: bdyr,
          Confirmed: 0,
          Cancelled: 0,
          Canceled: 0,
          Total: 0,
        };
        r.push(this[a.booked_date]);
      }
      this[a.booked_date][a.user_facing_status_localized]++;
      this[a.booked_date].Total++;
    }, Object.create(null));
    // return r;
    // console.log(r)
  }

  checkInToday = () => {
    this.state.reservations.forEach((res) => {
      let count = 0
      if (!this[res.booked_date] === "2020-11-20"){
        count++
      }
      return count
    })
  }

  renderDay = (date) => {
    let a = new Date(date);
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let dayOfWeek = days[a.getDay()];
    return dayOfWeek;
  };

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN);
    // console.log(this.state.reservations);
    // console.log(this.checkInToday())
    this.groupData(this.state.reservations)
    if (authToken) {
      return (
        <React.Fragment>
          <Container style={{ marginTop: "2em", height:"80vh" }}>
            <Row>
              <Col xs={12}>
                <h1>
                  Overview
                </h1>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1em", marginTop: "0.5em" }}>
              <Col lg={8} md={6} xs={12} style={{ marginBottom: "0.5em" }}>
                <h2>
                  Total Reservations -{" "}
                  <strong>{this.state.reservations.length}</strong>
                </h2>
              </Col>
              <Col lg={4} md={6} xs={12}></Col>
            </Row>
            {this.state.reservations.length > 0 ? (
              <>Hello... working on it!</>
            ) : (
              <h4 className="loading">
                Please wait while we load QuibbleRM...
              </h4>
            )}
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Main;
