import React, { Component } from "react";
import { Form, FormControl, Container, Row, Col, Button, Card} from "react-bootstrap";
import { Redirect } from "react-router-dom";
// import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL = "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

class SearchListing extends Component {
  state = {
    listings: [],
    options: [],
    selectedOptionlisting: null,
    filteredItems: [],
    search: "",
    top10: [],
    loading: false,
    // markets: ["San Juan, PR", "San Diego, CA", "Broward, FL", "New York, NY", "Portland, OR"],
  };

   componentDidMount() {

     axios
      .get(`${baseURL}/api/market`)
      .then((res) => {
        this.setState({ listings: res.data });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // console.log(this.state);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .get(`${baseURL}/api/pulse/get/${this.state.listingId}`)
    //   .get(`http://localhost:4000/api/pulse/get/${this.state.listingId}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // alert("Data has been capture successfully! Click ok to proceed to Listing Event Pricing Details.");
    await this.props.history.push(`/listing/${this.state.listingId}`);
  };

  searchAction = () =>{
    this.setState({
        loading: true
    })
  }

  marketCardList() {
      let markets = [
        "San Juan, PR",
        "San Diego, CA",
        "Miami, FL",
        "New York, NY",
        "Portland, OR",
        "Seattle, WA",
        "Washington, DC",
        "Santa Clara, CA",
        "Austin, TX",
        "Boston, MA",
      ];
       return markets.map((martket,i) => {
          return (
            <Card key={i} style={{ width: "13rem", marginLeft: "0.5rem", marginTop: "2rem" }}>
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>
                  {martket}
                </Card.Title>
                {/* <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text> */}
                {/* <center>
                  <Button variant="primary">Explore</Button>
                </center> */}
              </Card.Body>
            </Card>
          );
      })
  }

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN);
    // console.log(this.state)
    if (authToken) {
      return (
        <React.Fragment>
          <Container
            style={{
              marginTop: "20vh",
              marginBottom: "35vh",
              //   backgroundColor: "yellow",
            }}
          >
            <Row className="center2">
              <h1
                style={{
                  margin: "auto",
                  alignText: "center",
                }}
                className="pageHeader"
              >
                🔍 Market Insights
              </h1>
            </Row>
            <Row style={{ display: "block" }}>
              <Col lg="auto" className="center2">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Row>
                    <Col xs="auto">
                      <FormControl
                        style={{
                          marginBottom: "1em",
                          marginTop: "2em",
                          alignText: "center",
                        }}
                        name="listingId"
                        type="text"
                        className="mr-sm-1"
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col xs="auto">
                      <Button
                        style={{ marginBottom: "1em", marginTop: "2em" }}
                        variant="outline-info"
                        type="submit"
                        className={this.state.loading ? "loading" : ""}
                        onClick={this.searchAction}
                      >
                        {this.state.loading
                          ? "Gathering Data"
                          : "Search Listing"}
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </Col>
            </Row>
            {this.state.loading ? (
              <Container>
                <Row>
                  <h4 style={{ textAlign: "center", fontSize: "18px" }}>
                    Please wait one moment while QuibbleRM compiles the
                    requested data!
                  </h4>
                  <hr />
                  <h4 style={{ textAlign: "center", fontSize: "18px" }}>
                    <b>
                      You will be automatically taken to the listing overview.
                    </b>
                  </h4>
                </Row>
              </Container>
            ) : (
              <Row></Row>
            )}

            <Col>
              <Row style={{ marginTop: "10rem" }}>
                {this.marketCardList()}
                
              </Row>
              
            </Col>
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default SearchListing;