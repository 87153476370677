import React, { Component } from "react";
import { Form, FormControl, Container, Row, Col, Button} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Airbnb from "../img/airbnb.png";

// import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL =
      "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

class PricingPull extends Component {
  state = {
    listings: [],
    options: [],
    loading: false,
  };

  componentDidMount() {

    axios
      .get(`${baseURL}/api/market`)
      .then((res) => {
        this.setState({ listings: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .get(`${baseURL}/api/pulse/gets/${this.state.listingId}`)
      //   .get(`http://localhost:4000/api/pulse/get/${this.state.listingId}`)
      .then((res) => {
        console.log(res.data);
    })
    .catch((err) => {
        console.log(err);
    });
    alert("Data capture has been successfully submitted! Check qRM+db to get your OCC & Pricing Comps!");
    await this.props.history.push(`/qRMaccess/clients/`);
  };

  searchAction = () => {
    this.setState({
      loading: true,
    });
  };

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN);
    // console.log(this.state)
    if (authToken) {
      return (
        <React.Fragment>
          <Container
            style={{
              marginTop: "20vh",
              marginBottom: "35vh",
              //   backgroundColor: "yellow",
            }}
          >
            <Row className="center2">
              <h1
                style={{
                  margin: "auto",
                  alignText: "center",
                  marginBottom: "2rem",
                }}
                className="pageHeader"
              >
                <img
                  className="thumbnail-image"
                  src={Airbnb}
                  alt="settings"
                  height="65"
                />{" "}
                - $crapingQ
              </h1>
            </Row>
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <hr />
                <p>
                  <b>Instructions:</b>
                  <br />
                  <ol>
                    <li>Add all Airbnb Listing Id's separated by a coma.</li>
                    <li>
                      Please make sure <b>NOT</b> to leave spaces{" "}
                      <i>before, between and/or after the string</i> for everything to run smoothly!!
                    </li>
                  </ol>
                </p>
                <hr />
              </Col>
            </Row>
            <Row style={{ display: "block" }}>
              <Col lg="auto" className="center2">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Row>
                    <Col xs="auto">
                      <FormControl
                        style={{
                          marginBottom: "1em",
                          marginTop: "2em",
                          alignText: "center",
                        }}
                        name="listingId"
                        type="text"
                        placeholder="e.g. XXXX,YYYY,ZZZZ"
                        className="mr-sm-1"
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col xs="auto">
                      <Button
                        style={{ marginBottom: "1em", marginTop: "2em" }}
                        variant="outline-info"
                        type="submit"
                        className={this.state.loading ? "loading" : ""}
                        onClick={this.searchAction}
                      >
                        {this.state.loading
                          ? "Gathering Data"
                          : "Submit Listings"}
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </Col>
            </Row>
            {this.state.loading ? (
              <Container>
                <Row>
                  <h4 style={{ textAlign: "center", fontSize: "18px" }}>
                    Please wait one moment while QuibbleRM compiles the
                    requested data!
                  </h4>
                  <hr />
                  <h4 style={{ textAlign: "center", fontSize: "18px" }}>
                    <b>
                      You will be automatically taken to the listing overview.
                    </b>
                  </h4>
                </Row>
              </Container>
            ) : (
              <Row></Row>
            )}
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default PricingPull;
