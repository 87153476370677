import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Container, Row, Col, Button, Form, FormGroup, Modal } from "react-bootstrap";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import { AUTH_TOKEN } from "../constants.js";
import buildCalendar from "../services/buildCalendar";
import { Line } from "react-chartjs-2";
let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL = "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

const localizer = momentLocalizer(moment);

class EventCalendar extends Component {
  state = {
    events: [],
    pricing: [],
    unitData: [],
    oneDay: 1000 * 60 * 60 * 24,
    model: [],
    listingData: [],
    unitCustomizations: [],
    showPricingCol: true,
    customizationsModalShow: false,
    BasePrice: 0,
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  showAlert = (e) => {
    this.setState({ [e.target.name]: true });
  };

  toggleSwitch = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  convertDate = (date) => {
    console.log(moment.utc(date).toDate());
    return moment.utc(date).toDate();
  };

  hideAlert = (e) => {
    this.setState({ customizationsModalShow: false });
    // console.log(e, this.state)
  };

  componentDidMount() {
    axios
      .get(`${baseURL}/api/pulse/all/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ events: res.data,
                        pricing: res.data,
                      });
        let unitEvents = Object.values(res.data);
        // console.log(unitEvents)
        for (let i = 0; i < unitEvents.length; i++) {
          unitEvents[i].start = unitEvents[i].calendarDate + ":00:00:00";
          unitEvents[i].end = unitEvents[i].calendarDate + ":00:00:00";
          unitEvents[i].title =
            unitEvents[i].price + " -  🌜" + unitEvents[i].minNights;
        }
        console.log("this is the array", unitEvents);
        console.log("this is the res.data", res.data);

        this.setState({
          events: unitEvents,
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/unit/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ unitData: res.data, BasePrice: res.data[0].basePrice });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  mthdayOcc = (days) => {
    let occ = 0;
    for (let i = 0; i < 1; i++) {
      let counter = 0;
      this.state.events.slice(0, days).forEach((event) => {
        if (event.available !== true) {
          counter++;
        }
      });
      // console.log(counter)
      occ = ((counter / days) * 100).toFixed(0);
    }
    return occ;
  };

  handleSelectEvent = (e) => {
    console.log("Event!", e.price, e.listingId, e.calendarDate, e);
  };

  handleSelectSlot = (e) => {
    console.log(
      `Slot! ${e.action}`,
      "Start " + e.start.toISOString().slice(0, 10),
      "End " + e.end.toISOString().slice(0, 10)
    );
    if (e.action === "doubleClick") {
      this.setState({
        showPricingCol: false,
      });
    }
  };

  comparer = (unitEvents) => {
    return function (events) {
      return (
        unitEvents.filter(function (current) {
          return current.start === events.start && current.available === true;
        }).length === 1
      );
    };
  };

  handleStageChangeBasePrice = (e) => {
    e.preventDefault();
    console.log(e.target);
    // let payload = {
    //                 "basePrice": this.state.BasePrice,
    //                 "minPrice": this.state.MinPrice,
    //                 "maxPrice": this.state.MaxPrice,
    //               }
    // await axios
    //   .post(`${baseURL}/api/unit/${this.props.match.params.id}`, payload)
    //   .then((res) => {
    //     console.log(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    let unitEvents = buildCalendar(this.state.BasePrice);
    console.log("calendar OBJ >>>>", unitEvents);
    let updatedPriceCalendar = unitEvents.events.filter(
      this.comparer(this.state.events)
    );
    let updatedPricing = unitEvents.pricing;
    // let unitEvents = this.state.events
    // if (this.state.BasePrice) {
    //   for (let i = 0; i < unitEvents.length; i++) {
    //     unitEvents[i].start = unitEvents[i].calendarDate + ":00:00:00";
    //     unitEvents[i].end = unitEvents[i].calendarDate + ":00:00:00";
    //     unitEvents[i].title =
    //       "$" + this.state.BasePrice + " -  🌜" + unitEvents[i].minNights;
    //   }
    // } else {
    //   for (let i = 0; i < unitEvents.length; i++) {
    //       unitEvents[i].start = unitEvents[i].calendarDate + ":00:00:00";
    //       unitEvents[i].end = unitEvents[i].calendarDate + ":00:00:00";
    //       unitEvents[i].title = "$"+this.state.unitData[0].basePrice + " -  🌜" + unitEvents[i].minNights;
    //     }
    // }
    this.setState({
      events: updatedPriceCalendar,
      pricing: updatedPricing
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const customDayPropGetter = (date) => {
      if (date.getDate() === 1 || date.getDate() === 15)
        return {
          className: "special-day",
          style: {
            background: date.getDate() === 1 ? "#fbbf3380" : "#00cadc71",
          },
        };
      else return {};
    };

    const customSlotPropGetter = (date) => {
      if (date.getDate() === 1 || date.getDate() === 15)
        return {
          className: "special-day",
        };
      else return {};
    };
    const { events, pricing } = this.state;
    const authToken = localStorage.getItem(AUTH_TOKEN);
    let data = {
      labels: pricing.map((date) => {
        return date.calendarDate;
      }),
      datasets: [
        {
          label: "Daily Price ($)",
          backgroundColor: "rgba(48,190,213,0.2)",
          borderColor: "rgba(48,190,213,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(48,190,213,0.4)",
          hoverBorderColor: "rgba(48,190,213,1)",
          yAxisID: "y-axis-1",
          data: pricing.map((date) => {
            if(date.price !== String) {
              return date.price
            } else {
              return Number(date.price.replace(/[,|$]/g, ""));
            }
          }),
        },

      ],
    };
    let options = {
      elements: {
        point:{
          radius: 1
        }
      },
      title: {
        display: false,
        text: "Pricing Breakdown",
      },
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            type: "linear",
            display: false,
            position: "left",
            id: "y-axis-1",
            scaleLabel: {
              display: true,
              labelString: "($) Daily Price - USD",
            },
          },
        ],
        xAxes: [
          {
            display: false,
            position: "left",
            id: "x-axis-1",
          },
        ],
      },
    };
    // console.log(this.state.events);
    if (authToken) {
      // console.log(this.state.events);
      return (
        <>
          <Modal
            size="md"
            // style={{ maxHeight: "85vh", overflowY: "scroll" }}
            show={this.state.customizationsModalShow}
            onHide={(e) => this.hideAlert(e)}
            name="customizationsModalShow"
            aria-labelledby="modalCustomizations"
          >
            <Modal.Header closeButton>
              <Modal.Title id="modalCustomizations">
                <strong>Listing Customizations</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Last Minute Pricing"
                      checked={this.state.lmp}
                      name="lmp"
                      id="lmp"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.lmp ? "" : "blurry-text"}
                      >
                        Type
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="lmpType"
                        onChange={this.handleChange}
                        disabled={this.state.lmp ? false : true}
                      >
                        <option default value="none">
                          -
                        </option>
                        <option value="flat">Flat (%)</option>
                        <option value="gradual">Gradual (%)</option>
                        <option value="fixed">Fixed ($)</option>
                      </Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.lmp ? "" : "blurry-text"}
                      >
                        Amount
                      </Form.Label>
                      <Form.Control
                        name="lmpAmount"
                        onChange={this.handleChange}
                        disabled={this.state.lmp ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.lmp ? "" : "blurry-text"}
                      >
                        Within (X) Days
                      </Form.Label>
                      <Form.Control
                        name="lmpLeadTime"
                        onChange={this.handleChange}
                        disabled={this.state.lmp ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Orphan Day Pricing"
                      checked={this.state.odp}
                      name="odp"
                      id="odp"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.odp ? "" : "blurry-text"}
                      >
                        Type
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="odpType"
                        onChange={this.handleChange}
                        disabled={this.state.odp ? false : true}
                      >
                        <option default value="none">
                          -
                        </option>
                        <option value="flat">Flat (%)</option>
                        <option value="gradual">Gradual (%)</option>
                        <option value="fixed">Fixed ($)</option>
                      </Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.odp ? "" : "blurry-text"}
                      >
                        Amount
                      </Form.Label>
                      <Form.Control
                        name="odpAmount"
                        onChange={this.handleChange}
                        disabled={this.state.odp ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.odp ? "" : "blurry-text"}
                      >
                        Gaps of (X) Days
                      </Form.Label>
                      <Form.Control
                        name="opdLeadTime"
                        onChange={this.handleChange}
                        disabled={this.state.odp ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Minimum Far Out Pricing"
                      checked={this.state.mfop}
                      name="mfop"
                      id="mfop"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.mfop ? "" : "blurry-text"}
                      >
                        Type
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="mfopType"
                        onChange={this.handleChange}
                        disabled={this.state.mfop ? false : true}
                      >
                        <option default value="none">
                          -
                        </option>
                        <option value="flat">Flat (%)</option>
                        <option value="gradual">Gradual (%)</option>
                        <option value="fixed">Fixed ($)</option>
                      </Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.mfop ? "" : "blurry-text"}
                      >
                        Amount
                      </Form.Label>
                      <Form.Control
                        name="mfopAmount"
                        onChange={this.handleChange}
                        disabled={this.state.mfop ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.mfop ? "" : "blurry-text"}
                      >
                        Within (X) Days
                      </Form.Label>
                      <Form.Control
                        name="mfopLeadTime"
                        onChange={this.handleChange}
                        disabled={this.state.mfop ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Minimum Night Stay Update"
                      checked={this.state.msu}
                      name="msu"
                      id="msu"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.msu ? "" : "blurry-text"}
                      >
                        Last Minute
                      </Form.Label>
                      <Form.Control
                        name="lmMinNightStay"
                        onChange={this.handleChange}
                        placeholder="# of night(s)"
                        disabled={this.state.msu ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.msu ? "" : "blurry-text"}
                      >
                        Orphan Day(s)
                      </Form.Label>
                      <Form.Control
                        name="odMinNightStay"
                        onChange={this.handleChange}
                        placeholder="# of night(s)"
                        disabled={this.state.msu ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.msu ? "" : "blurry-text"}
                      >
                        Far Out Days
                      </Form.Label>
                      <Form.Control
                        name="foMinNightStay"
                        onChange={this.handleChange}
                        placeholder="# of night(s)"
                        disabled={this.state.msu ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <h5>
                  <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                    <Form.Check
                      type="switch"
                      label="Day of Week Adjustment"
                      checked={this.state.dowa}
                      name="dowa"
                      id="dowa"
                      onChange={(e) => this.toggleSwitch(e)}
                    />
                  </b>
                </h5>{" "}
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={4}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Type
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="dowaType"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      >
                        <option default value="none">
                          -
                        </option>
                        <option value="flat">Flat (%)</option>
                        <option value="gradual">Gradual (%)</option>
                        <option value="fixed">Fixed ($)</option>
                      </Form.Control>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12} lg={{ span: 2, offset: 2 }}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Mon
                      </Form.Label>
                      <Form.Control
                        name="dowaM"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Tue
                      </Form.Label>
                      <Form.Control
                        name="dowaT"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Wed
                      </Form.Label>
                      <Form.Control
                        name="dowaW"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Thu
                      </Form.Label>
                      <Form.Control
                        name="dowaTh"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "0.5rem" }}>
                    <Col xs={12} lg={{ span: 2, offset: 3 }}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Fri
                      </Form.Label>
                      <Form.Control
                        name="dowaF"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Sat
                      </Form.Label>
                      <Form.Control
                        name="dowaSa"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Label
                        className={this.state.dowa ? "" : "blurry-text"}
                      >
                        Sun
                      </Form.Label>
                      <Form.Control
                        name="dowaS"
                        onChange={this.handleChange}
                        disabled={this.state.dowa ? false : true}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <Row style={{ marginTop: "2rem" }}>
                  <Col>
                    <Form.Control
                      type="submit"
                      value="Submit Changes"
                      variant="outline-info ml-4"
                      className="bg-custom-1"
                      style={{ color: "white" }}
                    />
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
          <Container
            style={{ marginBottom: "2em", marginTop: "2em", height: "100%" }}
          >
            <Row>
              <Col
                xs={12}
                lg={{ span: 8, offset: 2 }}
                style={{ marginBottom: "2.5em" }}
              >
                <h1>
                  <center>Calendar View</center>
                </h1>
                <center>
                  <Button
                    as={Link}
                    to={"/events/" + this.props.match.params.id}
                    variant="info"
                  >
                    Pricing List
                  </Button>
                  <Button
                    variant="outline-warning ml-2"
                    as={Link}
                    to={"/listing/" + this.props.match.params.id}
                  >
                    Listing Overview
                  </Button>
                  <Button
                    onClick={(e) => this.showAlert(e)}
                    name="customizationsModalShow"
                    variant="secondary ml-2"
                    className="secondary"
                    // style={{ color: "white" }}
                  >
                    Customizations
                  </Button>
                </center>
                <br />
              </Col>
            </Row>

            <Row>
              {/* <Col lg={2}>World!</Col> */}
              <Col lg={{ span: this.state.showPricingCol ? 12 : 8 }}>
                <Calendar
                  localizer={localizer}
                  events={events}
                  views={{ month: true }}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 500, textAlign: "center" }}
                  defaultDate={new Date()}
                  selectable={true}
                  onSelectEvent={(e) => this.handleSelectEvent(e)}
                  onSelectSlot={(e) => this.handleSelectSlot(e)}
                  showMultiDayTimes={false}
                  toolbar={true}
                  popup={true}
                  // tooltipAccessor={true}
                  dayPropGetter={customDayPropGetter}
                  slotPropGetter={customSlotPropGetter}
                />
              </Col>
              <Col
                hidden={this.state.showPricingCol}
                lg={{ span: 3, offset: 1 }}
              >
                <Form
                // onSubmit={this.handleSubmit}
                >
                  <h5 style={{ marginBottom: "1rem" }}>
                    <b style={{ fontSize: "1em", color: "#00C8DC" }}>
                      <center>Adjust Pricing</center>
                    </b>
                  </h5>
                  <FormGroup>
                    <Row>
                      <Col lg={4}>
                        <Form.Label>
                          {" "}
                          <center>Min Price ($)</center>
                        </Form.Label>
                        <Form.Control
                          name="MinPrice"
                          onChange={this.handleChange}
                          placeholder={
                            this.state.unitData[0] === undefined
                              ? 0
                              : this.state.unitData[0].minPrice
                          }
                        ></Form.Control>
                      </Col>
                      <Col lg={4}>
                        <Form.Label>
                          <center>Base Price ($)</center>
                        </Form.Label>
                        <Form.Control
                          name="BasePrice"
                          onChange={this.handleChange}
                          placeholder={
                            this.state.unitData[0] === undefined
                              ? 0
                              : this.state.unitData[0].basePrice
                          }
                        ></Form.Control>
                      </Col>
                      <Col lg={4}>
                        <Form.Label>
                          <center>Max Price ($)</center>
                        </Form.Label>
                        <Form.Control
                          name="MaxPrice"
                          id="MaxPrice"
                          onChange={this.handleChange}
                          placeholder={
                            this.state.unitData[0] === undefined
                              ? 0
                              : this.state.unitData[0].maxPrice
                          }
                        ></Form.Control>
                      </Col>
                    </Row>
                  </FormGroup>
                  <hr />
                  <Col className="card mb-1" style={{ marginTop: "2rem" }}>
                    <Row style={{ marginTop: "1rem" }}>
                      <Col>
                        <center>
                          <h6>
                            <b>Customizations</b>
                          </h6>
                        </center>
                      </Col>
                    </Row>
                    <Row>
                      <ul>
                        <li>yos</li>
                      </ul>
                    </Row>
                  </Col>
                  <Row style={{ marginTop: "2rem" }}>
                    <Col>
                      <Form.Control
                        type="submit"
                        id="submitBasePrice"
                        name="submitBasePrice"
                        onClick={(e) => this.handleStageChangeBasePrice(e)}
                        value="Stage Changes"
                        variant="outline-info ml-4"
                        className="bg-custom-1"
                        style={{ color: "white" }}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>

            <Row style={{ marginTop: "2.5em" }}>
              <Line
                data={data}
                width={200}
                height={150}
                options={options}
                hidden={this.state.showPricingCol}
              />
            </Row>
          </Container>
        </>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default EventCalendar;