import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
} from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
// import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL =
      "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

class MyMarkets extends Component {
  state = {
    listings: [],
    options: [],
    selectedOptionlisting: null,
    filteredItems: [],
    search: "",
    top10: [],
    loading: false,
    // markets: ["San Juan, PR", "San Diego, CA", "Broward, FL", "New York, NY", "Portland, OR"],
  };

  componentDidMount() {
    axios
      .get(`${baseURL}/api/market`)
      .then((res) => {
        this.setState({ listings: res.data });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // console.log(this.state);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .get(`${baseURL}/api/pulse/get/${this.state.listingId}`)
      //   .get(`http://localhost:4000/api/pulse/get/${this.state.listingId}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // alert("Data has been capture successfully! Click ok to proceed to Listing Event Pricing Details.");
    await this.props.history.push(`/listing/${this.state.listingId}`);
  };

  searchAction = () => {
    this.setState({
      loading: true,
    });
  };

  marketCardList() {
    let markets = [
      "San Juan, PR",
      "San Diego, CA",
      "Miami, FL",
      "New York, NY",
      "Portland, OR",
      "Seattle, WA",
      "Washington, DC",
      "Santa Clara, CA",
      "Austin, TX",
      "Boston, MA",
    ];
    return markets.map((martket, i) => {
      return (
        <Card
          key={i}
          style={{ width: "13rem", marginLeft: "0.5rem", marginTop: "2rem" }}
        >
          <Card.Img variant="top" src="https://source.unsplash.com/random" />
          <Card.Body>
            <Card.Title style={{ textAlign: "center" }}>{martket}</Card.Title>
            <Card.Text>
              Total Properties: 110
              <br />
              <ul>
                <li>30-day Occ: 67%</li>
                <li>60-day Occ: 53%</li>
                <li>90-day Occ: 46%</li>
              </ul>
            </Card.Text>
            <center>
              <Button variant="warning">Explore Market</Button>
              <Button variant="success mt-2">View Listings</Button>
            </center>
          </Card.Body>
        </Card>
      );
    });
  }

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN);
    // console.log(this.state)
    if (authToken) {
      return (
        <React.Fragment>
          <Container
            style={{ marginTop: "2em", marginBottom: "2em", height: "100%" }}
          >
            <Row>
              <Col lg={10}>
                <h1>MyMarkets</h1>
              </Col>
              <Col lg={2}>
                <Button
                  variant="outline-primary mt-2"
                  as={Link}
                  to="/units/"
                >
                  Property List View
                </Button>
              </Col>
            </Row>
            <Col>
              <Row style={{ marginTop: "1rem" }}>{this.marketCardList()}</Row>
            </Col>
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default MyMarkets;
