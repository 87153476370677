import React, { Component } from 'react';
import { Form, Container, Row, Col, FormGroup } from "react-bootstrap";
import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";
import { Redirect } from 'react-router-dom';


class EditUnit extends Component {
  state = {
    unit: [],
  };

  async componentDidMount() {
    let user = await actions.isLoggedIn();
    this.setState({ ...user.data });

    await axios
      .get(`https://aqueous-wave-46255.herokuapp.com/https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com/api/unit/${this.props.match.params.id}`)
      // .get(`http://localhost:4000/api/unit/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ unit: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let newUnit = {
      name: this.state.name,
      type: this.state.type,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      nickname: this.state.nickname,
      tag : this.state.tag,
      airbnbId: this.state.airbnbId,
      averageRating: this.state.averageRating,
      bedrooms: this.state.bedrooms,
      bathrooms: this.state.bathrooms,
      reviewCount: this.state.reviewCount,
    };
    alert('Changes submitted!')
    this.props.history.push('/units')
    await axios
    // .post(`https://cors-anywhere.herokuapp.com/https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com/api/unit/${this.props.match.params.id}`, newUnit)
    .post(`http://localhost:4000/api/unit/${this.props.match.params.id}`, newUnit)
      .then(res => {
        console.log(res.data);
    })
    .catch( err => {
        console.log(err);
    });

  };

  render() {
       const authToken = localStorage.getItem(AUTH_TOKEN);
    console.log(this.state);
    if (authToken) {
      return (
        <Container style={{ marginTop: "2em", marginBottom: "2em" }}>
          <Row>
            <Col xs={12}>
              <h1>Edit Property Unit</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: "1em", marginTop: "0.5em" }}>
            <Col lg={12} md={6} xs={12} style={{ marginBottom: "0.5em" }}>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={6}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name="name"
                        required
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.listing_nickname}
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Label>Nickname</Form.Label>
                      <Form.Control
                        name="nickname"
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.nickname}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={6}>
                      <Form.Label>Space Type</Form.Label>
                      <Form.Control
                        name="type"
                        required
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.type}
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Label>Tag</Form.Label>
                      <Form.Control
                        name="tag"
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.tag}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={6}>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        name="address"
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.address}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        name="city"
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.city}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col xs={12} lg={6}>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        name="state"
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.state}
                      ></Form.Control>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        name="zip"
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.zip}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col xs={6} lg={3}>
                      <Form.Label>AirBnB ID</Form.Label>
                      <Form.Control
                        name="airbnbId"
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.airbnbId}
                      ></Form.Control>
                    </Col>
                    <Col xs={6} lg={3}>
                      <Form.Label>Average Rating</Form.Label>
                      <Form.Control
                        name="averageRating"
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.averageRating}
                      ></Form.Control>
                    </Col>
                    <Col xs={6} lg={3}>
                      <Form.Label>Bedrooms</Form.Label>
                      <Form.Control
                        name="bedrooms"
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.bedrooms}
                      ></Form.Control>
                    </Col>
                    <Col xs={6} lg={3}>
                      <Form.Label>Bathrooms</Form.Label>
                      <Form.Control
                        name="bathrooms"
                        onChange={this.handleChange}
                        defaultValue={this.state.unit.bathrooms}
                      ></Form.Control>
                    </Col>
                  </Row>
                </FormGroup>

                <Row>
                  <Col>
                    <Form.Control
                      type="submit"
                      value="Submit"
                      className="btn btn-success"
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default EditUnit;