import React, { Component } from 'react';
import { Container, Row, Col, Card } from "react-bootstrap";
import { Redirect } from "react-router-dom";
// import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";
import "bootstrap/js/src/collapse.js";
import { Bar } from "react-chartjs-2";
let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL = "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

const countFormat = [
  {
    // 0 - 999
    letter: "",
    limit: 1e3,
  },
  {
    // 1,000 - 999,999
    letter: "K",
    limit: 1e6,
  },
  {
    // 1,000,000 - 999,999,999
    letter: "M",
    limit: 1e9,
  },
  {
    // 1,000,000,000 - 999,999,999,999
    letter: "B",
    limit: 1e12,
  },
  {
    // 1,000,000,000,000 - 999,999,999,999,999
    letter: "T",
    limit: 1e15,
  },
];

class Hub extends Component {
  state = {
    reservations: [],
    showModalEvent: false,
    activeRes: [],
    activeMonthlyRes: [],
  };

  componentDidMount() {
    // let user = actions.isLoggedIn();
    // this.setState({ ...user.data });

    axios
      .get(`${baseURL}/api/reservation/activeYearlyRes`)
      .then((res) => {
        this.setState({ activeRes: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${baseURL}/api/reservation/activeMonthlyRes`)
      .then((res) => {
        this.setState({ activeMonthlyRes: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  occNights = (arr) => {
    let totalNights = 0;
    // let arr = this.state.activeRes;
    //   for (let i = 0; i < arr.length; i++) {
    let counter = 0;
    arr.forEach((booking) => {
      if (
        booking.user_facing_status_localized === "Confirmed" ||
        booking.user_facing_status_localized === "reserved"
      ) {
        counter += Number(booking.nights);
        // console.log(counter)
      }
    });
    totalNights = counter;
    return totalNights;
  };

  netRevenue = (arr) => {
    let totalRevenue = 0;
    // let arr = this.state.activeRes;
    //   for (let i = 0; i < arr.length; i++) {
    let counter = 0;
    arr.forEach((booking) => {
      if (
        booking.user_facing_status_localized === "Confirmed" ||
        booking.user_facing_status_localized === "reserved"
      ) {
        counter += Number(booking.earnings.replace(/[,|$]/g, ""));
        // console.log(counter);
      }
    });
    totalRevenue = counter;
    return totalRevenue;
  };

  aveLOS = (arr) => {
    let averageLOS = 0;
    let bookings = 0;
    // let arr = this.state.activeRes;
    //   for (let i = 0; i < arr.length; i++) {
    let counter = 0;
    arr.forEach((booking) => {
      if (
        booking.user_facing_status_localized === "Confirmed" ||
        booking.user_facing_status_localized === "reserved"
      ) {
        counter += booking.nights;
        bookings++;
        // console.log(counter);
      }
    });
    averageLOS = counter / bookings;
    // console.log(bookings, counter)
    return averageLOS;
  };

  // Format Method:
  formatCount = (value) => {
    let format = countFormat.find((format) => value < format.limit);

    value = (1000 * value) / format.limit;
    value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

    return value + format.letter;
  };

  render() {
    // let data2 = {
    //   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    //   datasets: [
    //     {
    //       label: "# of Red Votes",
    //       data: [12, 19, 3, 5, 2, 3],
    //       backgroundColor: "rgb(255, 99, 132)",
    //     },
    //     // {
    //     //   label: "# of Blue Votes",
    //     //   data: [2, 3, 20, 5, 1, 4],
    //     //   backgroundColor: "rgb(54, 162, 235)",
    //     // },
    //     // {
    //     //   label: "# of Green Votes",
    //     //   data: [3, 10, 13, 15, 22, 30],
    //     //   backgroundColor: "rgb(75, 192, 192)",
    //     // },
    //   ],
    // };

    let netRev = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "$ - Revenue",
          data: this.state.activeRes.map((reservations) => {
            let jan = 0;
            let feb = 0;
            // let mar = 0;
            // let apr = 0;
            // let may = 0;
            // let jun = 0;
            // let jul = 0;
            // let aug = 0;
            // let sep = 0;
            // let oct = 0;
            // let nov = 0;
            // let dec = 0;
            let arr = [];
            if (
              reservations.booked_date.slice(0, 7) === "2021-01" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              jan += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              console.log(jan);
            }
            if (
              reservations.booked_date.slice(0, 7) === "2021-02" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              feb += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              console.log(feb);
            }
            // if (
            //   reservations.booked_date.slice(0, 7) === "2021-03" &&
            //   reservations.user_facing_status_localized === "Confirmed"
            // ) {
            //   mar += Number(reservations.earnings.slice(1).replace(/,/g, ""));
            //   console.log(mar);
            // }
            // if (
            //   reservations.booked_date.slice(0, 7) === "2021-04" &&
            //   reservations.user_facing_status_localized === "Confirmed"
            // ) {
            //   apr += Number(reservations.earnings.slice(1).replace(/,/g, ""));
            //   console.log(apr);
            // }
            // if (
            //   reservations.booked_date.slice(0, 7) === "2021-05" &&
            //   reservations.user_facing_status_localized === "Confirmed"
            // ) {
            //   may += Number(reservations.earnings.slice(1).replace(/,/g, ""));
            //   console.log(may);
            // }
            // if (
            //   reservations.booked_date.slice(0, 7) === "2021-06" &&
            //   reservations.user_facing_status_localized === "Confirmed"
            // ) {
            //   jun += Number(reservations.earnings.slice(1).replace(/,/g, ""));
            //   console.log(jun);
            // }
            // if (
            //   reservations.booked_date.slice(0, 7) === "2021-07" &&
            //   reservations.user_facing_status_localized === "Confirmed"
            // ) {
            //   jul += Number(reservations.earnings.slice(1).replace(/,/g, ""));
            //   console.log(jul);
            // }
            // if (
            //   reservations.booked_date.slice(0, 7) === "2021-08" &&
            //   reservations.user_facing_status_localized === "Confirmed"
            // ) {
            //   aug += Number(reservations.earnings.slice(1).replace(/,/g, ""));
            //   console.log(aug);
            // }
            // if (
            //   reservations.booked_date.slice(0, 7) === "2021-09" &&
            //   reservations.user_facing_status_localized === "Confirmed"
            // ) {
            //   sep += Number(reservations.earnings.slice(1).replace(/,/g, ""));
            //   console.log(sep);
            // }
            // if (
            //   reservations.booked_date.slice(0, 7) === "2021-10" &&
            //   reservations.user_facing_status_localized === "Confirmed"
            // ) {
            //   oct += Number(reservations.earnings.slice(1).replace(/,/g, ""));
            //   console.log(oct);
            // }
            // if (
            //   reservations.booked_date.slice(0, 7) === "2021-11" &&
            //   reservations.user_facing_status_localized === "Confirmed"
            // ) {
            //   nov += Number(reservations.earnings.slice(1).replace(/,/g, ""));
            //   console.log(nov);
            // }
            // if (
            //   reservations.booked_date.slice(0, 7) === "2021-12" &&
            //   reservations.user_facing_status_localized === "Confirmed"
            // ) {
            //   dec += Number(reservations.earnings.slice(1).replace(/,/g, ""));
            //   console.log(dec);
            // }
            arr.push(jan, feb);
            // arr.push(jan,feb,mar,apr,may,jun,jul,aug,sep,oct,nov,dec);
            // console.log(arr);
            return arr;
          }),
          backgroundColor: "rgb(48, 190, 213)",
        },
        // {
        //   label: "# of Blue Votes",
        //   data: [2, 3, 20, 5, 1, 4],
        //   backgroundColor: "rgb(54, 162, 235)",
        // },
        // {
        //   label: "# of Green Votes",
        //   data: [3, 10, 13, 15, 22, 30],
        //   backgroundColor: "rgb(75, 192, 192)",
        // },
      ],
    };

    let bookedNights = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Count of Nights",
          data: this.state.activeRes.map((reservations) => {
            let jan = 0;
            let feb = 0;
            let arr = [];
            if (
              reservations.start_date.slice(0, 7) === "2021-01" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              jan += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              // console.log(jan);
            }
            if (
              reservations.start_date.slice(0, 7) === "2021-02" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              feb += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              // console.log(feb);
            }
            arr.push(jan, feb);
            // console.log(arr);
            return arr;
          }),
          backgroundColor: "rgb(243, 192, 82)",
        },
      ],
    };

    let netADR = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "$ - Revenue",
          data: this.state.activeRes.map((reservations) => {
            let jan = 0;
            let feb = 0;
            let arr = [];
            if (
              reservations.start_date.slice(0, 7) === "2021-01" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              jan += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              // console.log(jan);
            }
            if (
              reservations.start_date.slice(0, 7) === "2021-02" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              feb += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              // console.log(feb);
            }
            arr.push(jan, feb);
            // console.log(arr);
            return arr;
          }),
          backgroundColor: "rgb(255, 99, 132)",
        },
      ],
    };

    let reservations = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Reservation Count",
          data: this.state.activeRes.map((reservations) => {
            let jan = 0;
            let feb = 0;
            let arr = [];
            if (
              reservations.start_date.slice(0, 7) === "2021-01" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              jan += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              // console.log(jan);
            }
            if (
              reservations.start_date.slice(0, 7) === "2021-02" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              feb += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              // console.log(feb);
            }
            arr.push(jan, feb);
            // console.log(arr);
            return arr;
          }),
          backgroundColor: "rgb(95, 38, 132)",
        },
      ],
    };

    let aveLOS = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Number of Days",
          data: this.state.activeRes.map((reservations) => {
            let jan = 0;
            let feb = 0;
            let arr = [];
            if (
              reservations.start_date.slice(0, 7) === "2021-01" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              jan += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              console.log(jan);
            }
            if (
              reservations.start_date.slice(0, 7) === "2021-02" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              feb += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              console.log(feb);
            }
            arr.push(jan, feb);
            console.log(arr);
            return arr;
          }),
          backgroundColor: "rgb(8, 9, 132)",
        },
      ],
    };

    let netATV = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "$ - Revenue",
          data: this.state.activeRes.map((reservations) => {
            let jan = 0;
            let feb = 0;
            let arr = [];
            if (
              reservations.start_date.slice(0, 7) === "2021-01" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              jan += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              // console.log(jan);
            }
            if (
              reservations.start_date.slice(0, 7) === "2021-02" &&
              reservations.user_facing_status_localized === "Confirmed"
            ) {
              feb += Number(reservations.earnings.slice(1).replace(/,/g, ""));
              // console.log(feb);
            }
            arr.push(jan, feb);
            // console.log(arr);
            return arr;
          }),
          backgroundColor: "rgb(28, 148, 32)",
        },
      ],
    };

    let options2 = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    // // console.log(this.state.activeRes, this.state.activeMonthlyRes);
    // // console.log(this.occNights());
    const authToken = localStorage.getItem(AUTH_TOKEN);
    if (authToken) {
      return (
        <React.Fragment>
          <Container
            style={{ marginTop: "2em", height: "75vh", overflowY: "scroll" }}
          >
            <Row style={{ marginBottom: "2em" }}>
              <Col xs={12}>
                <h1>Analytics</h1>
              </Col>
            </Row>
            <h5>Month to Date</h5>
            <Row style={{ marginBottom: "3em" }} className="center2">
              <Card
                className="bg-custom-4"
                text="dark"
                variant="info"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title
                    style={{ textAlign: "center", fontSize: "1.2em" }}
                  >
                    $
                    {this.formatCount(Number(this.netRevenue(this.state.activeMonthlyRes)
                      .toFixed(2)
                      ))}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net Revenue</b>
                  </Card.Text>
                  {/* <Button name="monthlyRev" id="monthlyRev" onClick={(e)=>this.handleCardClick(e)} block variant="primary">Analytics</Button> */}
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title
                    style={{ textAlign: "center", fontSize: "1.2em" }}
                  >
                    {this.occNights(this.state.activeMonthlyRes)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Booked Nights</b>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title
                    style={{ textAlign: "center", fontSize: "1.2em" }}
                  >
                    $
                    {this.netRevenue(this.state.activeMonthlyRes) !== 0
                      ? (
                          Number(
                            this.netRevenue(
                              this.state.activeMonthlyRes
                            ).toFixed(2)
                          ) /
                          Number(this.occNights(this.state.activeMonthlyRes))
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "0.00"}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net ADR</b>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title
                    style={{ textAlign: "center", fontSize: "1.2em" }}
                  >
                    {this.state.activeMonthlyRes.length}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Reservations</b>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {this.aveLOS(this.state.activeMonthlyRes).toFixed(1)}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Average LOS</b>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-4"
                text="dark"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    $
                    {(
                      Number(this.netRevenue(this.state.activeMonthlyRes)) /
                      this.state.activeMonthlyRes.length
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net ATV</b>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </Row>
            <h5>Year to Date</h5>
            <Row style={{ marginBottom: "4rem" }} className="center2">
              <Card
                className="bg-custom-9"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title
                    style={{ textAlign: "center", fontSize: "1.2em" }}
                  >
                    $
                    {this.formatCount(Number(this.netRevenue(this.state.activeRes)
                      .toFixed(2)
                      ))}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net Revenue</b>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-9"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title
                    style={{ textAlign: "center", fontSize: "1.2em" }}
                  >
                    {this.occNights(this.state.activeRes)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Booked Nights</b>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-9"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    $
                    {(
                      Number(this.netRevenue(this.state.activeRes).toFixed(2)) /
                      Number(this.occNights(this.state.activeRes))
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net ADR</b>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-9"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title
                    style={{ textAlign: "center", fontSize: "1.2em" }}
                  >
                    {this.state.activeRes.length}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Reservations</b>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-9"
                style={{
                  width: "9rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {this.aveLOS(this.state.activeRes).toFixed(1)}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Average LOS</b>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              <Card
                className="bg-custom-9"
                style={{
                  width: "9.5rem",
                  marginTop: "2rem",
                  marginLeft: "2rem",
                }}
              >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    $
                    {(
                      Number(this.netRevenue(this.state.activeRes)) /
                      this.state.activeRes.length
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center", fontSize: "0.9em" }}>
                    <b>Net ATV</b>
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </Row>
            <hr />
            <Row style={{ marginTop: "4rem" }}>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Net Revenue
                </h5>
                <Bar data={netRev} width={15} height={15} options={options2} />
              </Col>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Booked Nights
                </h5>
                <Bar
                  data={bookedNights}
                  width={15}
                  height={15}
                  options={options2}
                />
              </Col>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Net ADR
                </h5>
                <Bar data={netADR} width={15} height={15} options={options2} />
              </Col>
            </Row>
            <Row style={{ marginTop: "4rem", marginBottom: "6rem" }}>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Reservations
                </h5>
                <Bar
                  data={reservations}
                  width={15}
                  height={15}
                  options={options2}
                />
              </Col>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Average LOS
                </h5>
                <Bar data={aveLOS} width={15} height={15} options={options2} />
              </Col>
              <Col lg={4}>
                <h5 style={{ marginBottom: "1rem", textAlign: "center" }}>
                  Net ATV
                </h5>
                <Bar data={netATV} width={15} height={15} options={options2} />
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Hub;