import React, { Component } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
// import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";
import "bootstrap/js/src/collapse.js";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL = "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

class Reservations extends Component {
  state = {
    reservations: [],
    showModalEvent: false,
    loaded: false,
    oneDay: 1000 * 60 * 60 * 24,
  };

   componentDidMount() {
    // let user = actions.isLoggedIn();
    // this.setState({ ...user.data });

     axios
      .get(`${baseURL}/api/reservation/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ reservations: res.data, loaded: true });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  renderDay = (date) => {
    let a = new Date(date);
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let dayOfWeek = days[a.getDay()];
    return dayOfWeek;
  };

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN);
    // console.log(this.state.reservations);
    if (authToken) {
      return (
        <React.Fragment>
          <Container style={{ marginTop: "2em", marginBottom: "2em" }}>
            <Row>
              <Col xs={12} style={{ marginBottom: "0.5em" }}>
                <h1>Listing Reservations </h1>
              </Col>
            </Row>
            {this.state.reservations.length > 0 ? (
              <Table
                // striped
                bordered
                hover
                responsive
                size="lg"
                style={{ textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Booking Date</th>
                    <th>Lead Time</th>
                    <th>Check-In</th>
                    <th>Check-Out</th>
                    <th>Nights</th>
                    <th>Total Payout</th>
                    <th>Guest Name</th>
                    <th>Guest Count</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.reservations.map((reservation, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tr
                          data-toggle="collapse"
                          data-target={".multi-collapse" + i}
                          aria-controls={"multiCollapseExample" + i}
                          key={i}
                        >
                          <td>{reservation.user_facing_status_localized}</td>
                          <td>{reservation.booked_date}</td>
                          <td>
                            {(
                              (new Date(reservation.booked_date) -
                                new Date(reservation.start_date)) /
                              this.state.oneDay
                            ).toFixed(0)}
                          </td>
                          <td>{reservation.start_date}</td>
                          <td>{reservation.end_date}</td>
                          <td>{reservation.nights}</td>
                          <td>{reservation.earnings}</td>
                          <td>{reservation.guest_user.full_name}</td>
                          <td>{reservation.guest_details.number_of_guests}</td>
                        </tr>
                        <tr
                          className={"collapse multi-collapse" + i}
                          id={"multiCollapseExample" + i}
                        >
                          <td>
                            {" "}
                            🔎 <strong>Explore</strong>
                          </td>
                          <td>
                            <Button size="sm">Date</Button>
                          </td>
                          <td>
                            <Button size="sm" variant="warning">
                              Month
                            </Button>
                          </td>
                          <td>
                            <Button size="sm" variant="success">
                              Year
                            </Button>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
            ) : !this.state.loaded ? (
              <h4 className="loading">
                Please wait while we load the reservations...
              </h4>
            ) : (
              <h4>No reservation data was found for this listing.</h4>
            )}
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Reservations;
