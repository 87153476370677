import axios from "axios";
let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL =
      "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

const service = axios.create({ withCredentials: false, baseURL });

const actions = {
  isLoggedIn: async () => {
    return await service.get("/api/user/is-logged-in");
  },
  register: async (user) => {
    return await service.post("/api/user/register", user);
  },
  logIn: async (user) => {
    return await service.post("/api/user/login", user);
  },
//   logOut: async () => {
//     return await service.get("/logout");
//   },
};

export default actions;
