import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  FormControl,
  Container,
  Row,
  Col,
  FormGroup,
} from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
// import actions from "../services/index";
import { AUTH_TOKEN } from "../constants.js";
import axios from "axios";

let baseURL;

process.env.NODE_ENV === "production"
  ? // ? (baseURL = 'here should be your production endpoint')
    (baseURL =
      "https://aqueous-wave-46255.herokuapp.com/https://quibblerm-api.herokuapp.com")
  : (baseURL = "http://localhost:4000");

class Clients extends Component {
  state = {
    listings: [],
    options: [],
    selectedOptionlisting: null,
    filteredItems: [],
    search: "",
    loading: false,
    units: [],
    clients: [],
  };

  componentDidMount() {
    
    axios
      .get(`${baseURL}/api/clients`)
      .then((res) => {
        this.setState({ clients: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });

    // axios
    //   .get(`${baseURL}/api/pulse/units`)
    //   .then((res) => {
    //     // console.log(res.data)
    //     this.setState({ pulses: res.data });
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .get(`${baseURL}/api/pulse/get/${this.state.listingId}`)
      // .get(`http://localhost:4000/api/pulse/get/${this.state.listingId}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    alert(
      "Data has been capture successfully! Click ok to proceed to Listing Event Pricing Details."
    );
    await this.props.history.push(`/events/${this.state.listingId}`);
  };

  filterOptions = (e) => {
    // eslint-disable-next-line
    let filteredItems = this.state.units.filter((listing) => {
      if (listing.listing_nickname) {
        return (
          listing.listing_nickname
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          listing.id.includes(String(e.target.value)) ||
          listing.smart_location
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          listing.owner_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        );
      }
    });
    this.setState({
      filteredItems,
      search: e.target.value,
    });
    console.log(this.state.filteredItems);
  };

  untanglePulse = () => {
    console.log(this.state.pulses);
  };

  mthdayOcc = (days, id) => {
    let occ = 0;
    let list = this.state.pulses;
    let array = list.filter((pulse) => pulse.listingId === id);
    for (let i = 0; i < 1; i++) {
      let counter = 0;
      array.slice(0, days).forEach((event) => {
        if (event.available !== true) {
          counter++;
        }
      });
      occ = ((counter / days) * 100).toFixed(0);
    }
    return occ;
  };

  showOptions = (e) => {
    return this.state.filteredItems.map((unit, i) => {
      return (
        <tr key={i}>
          <td>{unit.listing_nickname}</td>
          <td>{unit.listing_state}</td>
          <td>{unit.smart_location}</td>
          <td>{unit.id}</td>
          <td>
            <Button as={Link} to={"/events/" + unit.id} variant="info">
              Pricing List
            </Button>
            <Button
              as={Link}
              to={"/reservations/" + unit.id}
              variant="primary ml-2"
            >
              Reservations
            </Button>
            <Button
              as={Link}
              to={"/calendar/" + unit.id}
              variant="success ml-2"
            >
              🗓
            </Button>
            <Button as={Link} to={"/unit/" + unit.id} variant="warning ml-2">
              {" "}
              View{" "}
            </Button>
          </td>
        </tr>
      );
    });
  };

  

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN);
    if (authToken) {
      return (
        <React.Fragment>
          <Container style={{ marginTop: "2em", height: "75vh" }}>
            <Row>
              <Col xs={12}>
                <h1>QuibbleRM Clients</h1>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1em", marginTop: "0.5em" }}>
              <Col lg={8} md={6} xs={12} style={{ marginBottom: "0.5em" }}>
                <Form inline>
                  <FormGroup onChange={this.filterOptions}>
                    <FormControl
                      type="text"
                      name="search"
                      placeholder="Type your search..."
                      className="mr-sm-2"
                      onChange={this.handleChange}
                    />
                    <Button
                      variant="info"
                      className="bg-custom-1"
                      style={{ color: "white" }}
                    >
                      Search
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Table
              striped
              bordered
              hover
              responsive
              size="sm"
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>-qRM-</th>
                  <th>Client ID</th>
                  <th>Name</th>
                  <th>Active Count</th>
                  <th>Unit Count</th>
                  <th>Location</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.clients.map((client, i) => {
                  return (
                    <tr key={i}>
                      <td>{client.activeCount > 0 ? " 🟢 " : " 🔴  "}</td>
                      <td>{client.clientId}</td>
                      <td>{client.name}</td>
                      <td>{client.activeCount}</td>
                      <td>{client.unitCount}</td>
                      <td>{client.location}</td>
                      <td>
                        <Button
                          as={Link}
                          to={`/qRMaccess/clientListings/${client.clientId}`}
                          size="sm"
                          variant="primary mr-2"
                        >
                          Listings
                        </Button>
                        <Button
                          as={Link}
                          to={`/qRMaccess/hub/${client.clientId}`}
                          size="sm"
                          variant="info mr-2"
                        >
                          Performance
                        </Button>
                        <Button size="sm" variant="warning mr-2">
                          Edit
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Container>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Clients;
